import { FunctionComponent } from "react";

import { useParams } from "react-router-dom";

import { BaseQuestionnairePage } from "../EngagementQuestionnairePage/BaseQuestionnairePage";

import { useProjectQuestionnaire } from "./useProjectQuestionnaire";

const ProjectQuestionnairePage: FunctionComponent = () => {
    const { engagementId, projectId, dynamicFormId } = useParams();

    const args = useProjectQuestionnaire(engagementId || "", projectId || "", dynamicFormId || "");

    return <BaseQuestionnairePage {...args} />;
};

export default ProjectQuestionnairePage;
