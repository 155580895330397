import { FunctionComponent } from "react";

import { Badge, Box, TableContainer } from "@chakra-ui/react";
import { IconFileDownload } from "@tabler/icons-react";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable, DownloadButton, formatFileSize, formatTimestampStr, useToast } from "am-tax-fe-core";
import { useTranslation } from "react-i18next";

import { Document, downloadEngagementDocument } from "../../api";

export interface Props {
    engagementId: string;
    documents: Document[];
}

export const DocumentTable: FunctionComponent<Props> = ({ engagementId, documents }) => {
    const toast = useToast();
    const { t } = useTranslation("Documents");
    const columnHelper = createColumnHelper<Document>();

    return (
        <TableContainer>
            <DataTable
                tableProps={{ size: "sm" }}
                data={documents}
                columns={[
                    columnHelper.accessor("name", {
                        header: t("filename"),
                        cell: ({ row: { original: document } }) => (
                            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                <Box>{document.name}</Box>
                                <Box>{document.versionNumber > 1 && <Badge colorScheme={"orange"}>V{document.versionNumber}</Badge>}</Box>
                            </Box>
                        ),
                    }),
                    columnHelper.accessor("uploadedDate", {
                        header: t("uploadedheader"),
                        cell: ({ getValue }) => formatTimestampStr(getValue()),
                    }),
                    columnHelper.accessor("uploadedByName", { header: t("uploadedBy") }),
                    columnHelper.accessor("size", {
                        header: t("size"),
                        cell: props => {
                            return formatFileSize(props.getValue());
                        },
                    }),
                    columnHelper.accessor("id", {
                        header: "",
                        enableColumnFilter: false,
                        enableSorting: false,
                        cell: ({ row: { original: document } }) => (
                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <DownloadButton
                                    icon={<IconFileDownload />}
                                    variant={"naked"}
                                    bytesExpectedOverride={document.size}
                                    downloadFn={async progressCallback => {
                                        try {
                                            const blob = await downloadEngagementDocument({
                                                engagementId: engagementId,
                                                documentId: document.id,
                                                documentVersionId: document.versionId,
                                                progressCallback,
                                            });
                                            return { blob, fileName: document.name };
                                        } catch (ex) {
                                            toast({
                                                title: t("failureTitle"),
                                                description: t("failureDescription"),
                                                status: "error",
                                                duration: 6000,
                                                isClosable: true,
                                            });
                                            throw ex;
                                        }
                                    }}
                                />
                            </Box>
                        ),
                    }),
                ]}
            />
        </TableContainer>
    );
};
