import type { SyntheticEvent } from "react";
import { memo } from "react";

import { chakra, Checkbox, Box, HStack, IconButton } from "@chakra-ui/react";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";

import { TreeNode } from "../../api";

interface Props {
    key: string;
    node: TreeNode;
    parent?: TreeNode;
    last: boolean;
    toggleSelect: (nodeKey: string) => void;
    toggleExpand: (nodeKey: string) => void;
    isReadOnly: boolean;
    isDisabled: boolean;
}

export const TreeNodeItem = memo(({ node, parent, toggleSelect, toggleExpand, isReadOnly = true, isDisabled = false }: Props) => {
    const nodeChildren = node.children ?? [];

    const hasParent = !!parent;
    const hasChildren = (node: TreeNode): boolean => !!node.children && node.children.length > 0;

    const onTogglerClick = (event: SyntheticEvent<HTMLButtonElement>): void => {
        toggleExpand(node.key);
        event.preventDefault();
        event.stopPropagation();
    };

    const createLabel = (): JSX.Element => <Box as="span">{node.label}</Box>;

    const createCheckbox = (): JSX.Element => (
        <Checkbox isChecked={node.selected === true} isReadOnly={isReadOnly} isDisabled={isDisabled} onChange={() => toggleSelect(node.key)} />
    );

    const createToggler = (): JSX.Element | null => {
        if (!nodeChildren?.length) return null;

        return (
            <IconButton
                isRound
                size="xs"
                variant="link"
                colorScheme="blue"
                aria-label="Expand"
                onClick={onTogglerClick}
                fontSize="24px"
                icon={node.expanded ? <IconChevronDown /> : <IconChevronRight />}
            />
        );
    };

    const createContent = (): JSX.Element => (
        <Box
            role="treeitem"
            tabIndex={0}
            sx={{
                pl: nodeChildren?.length ? 0 : 14,
                fontWeight: node.selected ? "medium" : "normal",
            }}
        >
            <HStack>
                {createToggler()}
                {createCheckbox()}
                {createLabel()}
            </HStack>
        </Box>
    );

    const createChildren = (): JSX.Element | null => {
        if (!nodeChildren?.length || !node.expanded) return null;

        return (
            <chakra.ul
                role="group"
                sx={{
                    pl: hasChildren(node) ? 0 : 6,
                    listStyleType: "none",
                }}
            >
                {nodeChildren.map((descendant: TreeNode, key: number) => (
                    <TreeNodeItem
                        key={descendant.key}
                        node={descendant}
                        parent={node}
                        last={key === nodeChildren.length - 1}
                        toggleSelect={toggleSelect}
                        toggleExpand={toggleExpand}
                        isReadOnly={isReadOnly}
                        isDisabled={isDisabled}
                    />
                ))}
            </chakra.ul>
        );
    };

    const createNode = (): JSX.Element => (
        <chakra.li
            sx={{
                pl: hasParent && hasChildren(node) ? 7 : 1,
            }}
        >
            {createContent()}
            {createChildren()}
        </chakra.li>
    );

    return createNode();
});
