import { GdprConsent, GdprConsentStorageService, getQueryClient } from "am-tax-fe-core";

import { getGdprConsent, queryKeys, saveGdprConsent } from "../api";

class GdprConsentStorageServiceImpl implements GdprConsentStorageService {
    async loadConsent(): Promise<GdprConsent | undefined> {
        const consent = await getQueryClient().fetchQuery({ ...queryKeys.users.gdprConsent, queryFn: async () => (await getGdprConsent()) || null });
        return consent || undefined;
    }
    async saveConsent(consent: GdprConsent) {
        await saveGdprConsent(consent);
        await getQueryClient().invalidateQueries(queryKeys.users.gdprConsent);
    }
}

export const gdprConsentStorageService = new GdprConsentStorageServiceImpl();
