import { FunctionComponent, useState } from "react";

import { Button, Card, CardBody, Flex, FormControl, FormLabel } from "@chakra-ui/react";
import { AutoCompleteSingle } from "am-tax-fe-core";
import debounce from "lodash.debounce";

import { ClientSearchResponse, useClientSearchQueryFn, UserSearchLevel } from "../../api/index.ts";

import { ProvisionCustomEngagementModal } from "./ProvisionCustomEngagementModal.tsx";

export const CreateEngagementPanel: FunctionComponent = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedClient, setSelectedClient] = useState<ClientSearchResponse | undefined>();

    // ---- Setup User Search ----
    const clientSearchFn = useClientSearchQueryFn();
    const searchClients = debounce((searchText: string, level: UserSearchLevel, callback): void => {
        if (searchText.length > 0) {
            clientSearchFn({ searchText }).then(clients => {
                const results: { value: ClientSearchResponse | undefined; label: string }[] = [];
                results.push({ value: undefined, label: "Create Engagement Under Custom Client" });
                results.push(...clients.map((client: ClientSearchResponse) => ({ value: client, label: `${client.clientName}` })));
                callback(results);
            });
        }
    }, 250);

    const openModal = (client?: ClientSearchResponse | undefined) => {
        setSelectedClient(client);
        setShowModal(true);
    };

    return (
        <>
            <Flex direction={"column"} gap={"1rem"}>
                <Card>
                    <CardBody bgGradient={`linear(to-br, blue.700, blue.500)`} borderRadius={"lg"}>
                        <FormControl>
                            <FormLabel color={"whiteAlpha.800"}>Client</FormLabel>
                            <FormLabel fontWeight={"400"} color={"whiteAlpha.800"}>
                                Which client will this engagement be a part of?
                            </FormLabel>
                            <AutoCompleteSingle
                                loadOptions={(searchText, callback) => searchClients(searchText, UserSearchLevel.LD, callback)}
                                placeholder="client name..."
                                noOptionsMessage={obj =>
                                    obj.inputValue.length > 0 ? "No Clients Found." : "Type at least 1 character to initiate client search."
                                }
                                loadingMessage={obj =>
                                    obj.inputValue.length > 0 ? "Searching for Clients..." : "Type at least 1 character to initiate client search."
                                }
                                onChange={opt => openModal(opt?.value ? (opt.value as ClientSearchResponse) : undefined)}
                                value={null}
                            />
                            <Button variant="link" color="blue.100" sx={{ mt: "1rem" }} onClick={() => openModal()}>
                                Create Engagement Under Custom Client
                            </Button>
                        </FormControl>
                    </CardBody>
                </Card>
            </Flex>
            <ProvisionCustomEngagementModal show={showModal} onClose={() => setShowModal(false)} selected={selectedClient} />
        </>
    );
};
