import { FunctionComponent } from "react";

import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Heading, IconButton, Text, Tooltip } from "@chakra-ui/react";
import { IconTrash, IconEye, IconEyeOff } from "@tabler/icons-react";
import { useConfirm } from "am-tax-fe-core";

import { RequestCommentModel, ConversationResponse, DeleteRequestConversationArgs, PublishRequestConversationArgs, User } from "../../api";
import { useCurrentUser, useDeleteRequestConveration, useTogglePublishRequestConveration } from "../../api/apiQueries";

import CommentItem from "./CommentItem";
import CreateCommentPanel from "./CreateCommentPanel";

interface Props {
    conversation: ConversationResponse;
    engagementId: string;
    projectId?: string;
}

const CovnersationItem: FunctionComponent<Props> = ({ engagementId, projectId, conversation }: Props) => {
    const { confirm: confirmDelete, ConfirmDialog: ConfirmDeleteDialog } = useConfirm({
        title: "Delete conversation",
        prompt: "Are you sure you want to delete this conversation?",
    });

    const currentUserQuery = useCurrentUser();
    const currentUser = currentUserQuery.data as User;

    const deleteConversationQuery = useDeleteRequestConveration();
    const deleteConversation = async () => {
        const args: DeleteRequestConversationArgs = {
            requestId: conversation.requestId,
            conversationId: conversation.id,
            engagementId,
            projectId,
        };
        await deleteConversationQuery.mutateAsync(args);
    };

    const getConversationDetails = () => {
        let title = `${conversation.user?.firstName} ${conversation.user?.lastName}`;
        if (conversation.dateCreated) {
            const formattedDate = new Date(conversation.dateCreated).toLocaleDateString("en-us", {
                weekday: "short",
                //year: "numeric",
                month: "short",
                day: "numeric",
            });
            title = `${title} - ${formattedDate}`;
        }
        return title;
    };

    const handleDelete = async (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (await confirmDelete()) {
            deleteConversation();
        }
    };

    const toggleConversationPublishQuery = useTogglePublishRequestConveration();
    const handleTogglePublish = async (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        const args: PublishRequestConversationArgs = {
            requestId: conversation.requestId,
            conversationId: conversation.id,
            engagementId,
            projectId,
            publish: !conversation.isPublished,
        };
        await toggleConversationPublishQuery.mutateAsync(args);
    };

    const sortByCreatedDate = (c1: RequestCommentModel, c2: RequestCommentModel) => {
        if (!c1.dateCreated || !c2.dateCreated) return 0;
        return new Date(c1.dateCreated).getTime() > new Date(c2.dateCreated).getTime() ? 1 : -1;
    };

    return (
        <>
            <AccordionItem bg={conversation.isPublished ? "inherit" : "blackAlpha.50"}>
                <AccordionButton as="div">
                    <Box as="span" flex="1" textAlign="left" my=".5rem">
                        <Heading fontSize="lg" fontWeight="500" mb=".5rem">
                            {conversation.title}
                        </Heading>
                        <Text color="gray.500">{getConversationDetails()}</Text>
                    </Box>
                    <Flex mr="1rem" justify="flex-start" gap=".5rem">
                        {currentUser && currentUser.isInternal && (
                            <Tooltip label={conversation.isPublished ? "Hide conversation from clients" : "Hidden from clients"}>
                                <IconButton
                                    aria-label="Hide conversation from clients"
                                    icon={conversation.isPublished ? <IconEye size={18} /> : <IconEyeOff size={18} />}
                                    onClick={handleTogglePublish}
                                    isLoading={toggleConversationPublishQuery.isPending}
                                    variant="naked"
                                    ml=".25rem"
                                    color="gray.500"
                                />
                            </Tooltip>
                        )}
                        {currentUser && currentUser.id === conversation.user?.id && (
                            <Tooltip label="Delete conversation">
                                <IconButton
                                    aria-label="Delete conversation"
                                    icon={<IconTrash size={18} />}
                                    onClick={handleDelete}
                                    isLoading={deleteConversationQuery.isPending}
                                    variant="naked"
                                    ml=".25rem"
                                    color="gray.500"
                                />
                            </Tooltip>
                        )}
                    </Flex>

                    <AccordionIcon
                        _hover={{
                            cursor: "pointer",
                        }}
                    />
                </AccordionButton>

                <AccordionPanel py="1rem">
                    {conversation.comments.length > 0 ? (
                        conversation.comments
                            .sort(sortByCreatedDate)
                            .map(x => (
                                <CommentItem
                                    key={x.id}
                                    engagementId={engagementId}
                                    projectId={projectId}
                                    requestId={conversation.requestId}
                                    comment={x}
                                    isConversationPublished={conversation.isPublished}
                                />
                            ))
                    ) : (
                        <Text>No comments..</Text>
                    )}

                    <CreateCommentPanel requestId={conversation.requestId} conversationId={conversation.id} engagementId={engagementId} projectId={projectId} />
                </AccordionPanel>
            </AccordionItem>
            <ConfirmDeleteDialog />
        </>
    );
};

export default CovnersationItem;
