import { FunctionComponent } from "react";

import { useParams } from "react-router-dom";

import { useProjectTaxNotices } from "../../api";
import { TaxNoticesPage } from "../EngagementTaxNoticesPage/TaxNoticesPage.tsx";

export const ProjectTaxNoticesPage: FunctionComponent = () => {
    const { engagementId, projectId } = useParams();
    const projectTaxNoticesQuery = useProjectTaxNotices(engagementId ?? "", projectId ?? "");

    return <TaxNoticesPage taxNoticesQuery={projectTaxNoticesQuery} />;
};
