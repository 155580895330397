import * as React from "react";

import { Outlet } from "react-router-dom";

import { Permission, SecurityArea } from "../auth/index.ts";
import { AppRouteGuard } from "../components/AppRouteGuard/AppRouteGuard.tsx";
import { EngagementRouteGuard } from "../components/EngagementRouteGuard/index.ts";
import { ProjectRouteGuard } from "../components/ProjectRouteGuard/index.ts";

export function appGuard(hasAny: Permission[], element: React.ReactNode) {
    return {
        element: <AppRouteGuard hasAny={hasAny} render={element} />,
    };
}
export function appGuardWithOutlet(hasAny: Permission[], element: React.ReactNode) {
    return {
        element: (
            <AppRouteGuard
                hasAny={hasAny}
                render={
                    <>
                        {element}
                        <Outlet />
                    </>
                }
            />
        ),
    };
}

export function engagementGuard(securityArea: SecurityArea, element: React.ReactNode) {
    return {
        element: <EngagementRouteGuard securityArea={securityArea} render={element} />,
    };
}
export function engagementGuardWithOutlet(securityArea: SecurityArea, element: React.ReactNode) {
    return {
        element: (
            <EngagementRouteGuard
                securityArea={securityArea}
                render={
                    <>
                        {element}
                        <Outlet />
                    </>
                }
            />
        ),
    };
}

export function projectGuard(securityArea: SecurityArea, element: React.ReactNode) {
    return {
        element: <ProjectRouteGuard securityArea={securityArea} render={element} />,
    };
}
export function projectGuardWithOutlet(securityArea: SecurityArea, element: React.ReactNode) {
    return {
        element: (
            <ProjectRouteGuard
                securityArea={securityArea}
                render={
                    <>
                        {element}
                        <Outlet />
                    </>
                }
            />
        ),
    };
}
