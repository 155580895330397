import { FunctionComponent } from "react";

import { Card, CardBody, CardHeader } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { TaxNoticeForm } from "../EngagementTaxNoticePage/TaxNoticeForm.tsx";
import { Page } from "../Page.tsx";

import { useTaxNoticeFormForProject } from "./useTaxNoticeFormForProject.ts";

//import "../../../../AM.Tax.ClientPortal.Data.Entities/Definitions/ColumnDefinitionConstants.cs";

export const ProjectTaxNoticePage: FunctionComponent = () => {
    const { engagementId, projectId, taxNoticeId } = useParams();

    const taxNoticeFormProps = useTaxNoticeFormForProject(engagementId ?? "", projectId ?? "", taxNoticeId ?? "");

    return (
        <Page title={`Add Tax Notice`}>
            <Card>
                <CardHeader bgGradient="linear(to-br, purple.500, purple.300)"></CardHeader>
                <CardBody>
                    <TaxNoticeForm {...taxNoticeFormProps} />
                </CardBody>
            </Card>
        </Page>
    );
};
