import { FunctionComponent } from "react";

import { useParams } from "react-router-dom";

import { BaseApplicationsPage } from "../EngagementApplicationsPage/BaseApplicationsPage";

export const ProjectApplicationsPage: FunctionComponent = () => {
    const { engagementId, projectId } = useParams();
    return <BaseApplicationsPage engagementId={engagementId || ""} projectId={projectId || ""} />;
};
