import { FunctionComponent } from "react";

import { Image, Text, VStack } from "@chakra-ui/react";

import art from "../../assets/images/void.svg";
import { Page } from "../Page";

export const UnauthorizedPage: FunctionComponent = () => {
    return (
        <Page title={"Unauthorized"}>
            <VStack justify="center" direction={"column"}>
                <Image src={art} boxSize="250px" mb={16} />

                <Text fontSize="18px" mt={3} mb={2} textTransform={"uppercase"}>
                    Unauthorized
                </Text>
                <Text color={"gray.500"} mb={6}>
                    You do not have permissions to view this content.
                </Text>
            </VStack>
        </Page>
    );
};
