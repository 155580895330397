export enum EngagementArea {
    _name = "EngagementArea",
    create = "urn:am:tax:clientportal:engagement:create",
    read = "urn:am:tax:clientportal:engagement:read",
    update = "urn:am:tax:clientportal:engagement:update",
    delete = "urn:am:tax:clientportal:engagement:delete",
    activate = "urn:am:tax:clientportal:engagement:activate",
}
export enum ProjectArea {
    _name = "ProjectArea",
    create = "urn:am:tax:clientportal:project:create",
    read = "urn:am:tax:clientportal:project:read",
    update = "urn:am:tax:clientportal:project:update",
    delete = "urn:am:tax:clientportal:project:delete",
}
export enum UserArea {
    _name = "UserArea",
    create = "urn:am:tax:clientportal:user:create",
    read = "urn:am:tax:clientportal:user:read",
    update = "urn:am:tax:clientportal:user:update",
    delete = "urn:am:tax:clientportal:user:delete",
}
export enum RequestArea {
    _name = "RequestArea",
    create = "urn:am:tax:clientportal:request:create",
    read = "urn:am:tax:clientportal:request:read",
    update = "urn:am:tax:clientportal:request:update",
    reassign = "urn:am:tax:clientportal:request:update:reassign",
    delete = "urn:am:tax:clientportal:request:delete",
}
export enum TaxNoticeArea {
    _name = "TaxNoticeArea",
    create = "urn:am:tax:clientportal:taxnotice:create",
    read = "urn:am:tax:clientportal:taxnotice:read",
    update = "urn:am:tax:clientportal:taxnotice:update",
    delete = "urn:am:tax:clientportal:taxnotice:delete",
}
export enum DocumentArea {
    _name = "DocumentArea",
    create = "urn:am:tax:clientportal:clientdocument:create",
    read = "urn:am:tax:clientportal:clientdocument:read",
    update = "urn:am:tax:clientportal:clientdocument:update",
    delete = "urn:am:tax:clientportal:clientdocument:delete",
    download = "urn:am:tax:clientportal:clientdocument:download",
}
export enum DeliverableArea {
    _name = "DeliverablesArea",
    create = "urn:am:tax:clientportal:clientdeliverable:create",
    read = "urn:am:tax:clientportal:clientdeliverable:read",
    update = "urn:am:tax:clientportal:clientdeliverable:update",
    delete = "urn:am:tax:clientportal:clientdeliverable:delete",
}
export enum ReportArea {
    _name = "ReportArea",
    create = "urn:am:tax:clientportal:report:create",
    read = "urn:am:tax:clientportal:report:read",
    update = "urn:am:tax:clientportal:report:update",
    delete = "urn:am:tax:clientportal:report:delete",
}
export enum NoticeArea {
    _name = "NoticeArea",
}
export enum CalendarArea {
    _name = "CalendarArea",
    create = "urn:am:tax:clientportal:calendarevent:create",
    read = "urn:am:tax:clientportal:calendarevent:read",
    update = "urn:am:tax:clientportal:calendarevent:update",
    delete = "urn:am:tax:clientportal:calendarevent:delete",
}
export enum EngagementSettingArea {
    _name = "EngagementSettingArea",
    read = "urn:am:tax:clientportal:engagementsetting:read",
    update = "urn:am:tax:clientportal:engagementsetting:update",
}
export enum ActivityArea {
    _name = "ActivityArea",
    read = "urn:am:tax:clientportal:activity:read",
}
export enum WorkflowArea {
    _name = "WorkflowArea",
    read = "urn:am:tax:clientportal:workflow:read",
}
export enum EngagementNotificationSettingArea {
    _name = "EngagementNotificationSettingArea",
    read = "urn:am:tax:clientportal:notificationsetting:read",
    update = "urn:am:tax:clientportal:notificationsetting:update",
}
export enum AdminArea {
    _name = "AdminArea",
    update = "urn:am:tax:clientportal:admin:update",
    report = "urn:am:tax:clientportal:admin:report",
    create = "urn:am:tax:clientportal:engagement:create",
}

export const BoxOnlyUserRoleId = 9;

export const allAreas = [
    EngagementArea,
    ProjectArea,
    UserArea,
    RequestArea,
    TaxNoticeArea,
    DocumentArea,
    DeliverableArea,
    ReportArea,
    CalendarArea,
    ActivityArea,
    EngagementSettingArea,
    WorkflowArea,
    EngagementNotificationSettingArea,
    AdminArea,
] as const;

type AllAreasType = typeof allAreas;

export type SecurityArea = AllAreasType[number]; // this is equivalent to: typeof UserArea | typeof RequestArea | typeof DocumentArea;

export type PermissionsByAreaType<A extends SecurityArea> = A extends typeof EngagementArea
    ? EngagementArea
    : A extends typeof ProjectArea
      ? ProjectArea
      : A extends typeof UserArea
        ? UserArea
        : A extends typeof RequestArea
          ? RequestArea
          : A extends typeof TaxNoticeArea
            ? TaxNoticeArea
            : A extends typeof DocumentArea
              ? DocumentArea
              : A extends typeof DeliverableArea
                ? DeliverableArea
                : A extends typeof ReportArea
                  ? ReportArea
                  : A extends typeof CalendarArea
                    ? CalendarArea
                    : A extends typeof EngagementSettingArea
                      ? EngagementSettingArea
                      : A extends typeof ActivityArea
                        ? ActivityArea
                        : A extends typeof WorkflowArea
                          ? WorkflowArea
                          : A extends typeof EngagementNotificationSettingArea
                            ? EngagementNotificationSettingArea
                            : A extends typeof AdminArea
                              ? AdminArea
                              : "PermissionsByAreaTypeError: if you see this then search for PermissionsByAreaTypeError in the code";

export type Permission = PermissionsByAreaType<AllAreasType[number]>; // this is equivalent to: UserArea | RequestArea | DocumentArea;

export type AppPermissions = {
    loading: boolean;
    isInternal: boolean;
    showArea: (area: SecurityArea) => boolean;
    has: (permission: Permission | null) => boolean;
    hasAny: (...permission: Permission[]) => boolean;
};

export type AreaPermissions<A extends SecurityArea> = {
    loading: boolean;
    isInternal: boolean;
    showArea: boolean;
    has: (permission: PermissionsByAreaType<A> | null) => boolean;
    hasAny: (...permission: PermissionsByAreaType<A>[]) => boolean;
};
