import { FunctionComponent } from "react";

import { useParams } from "react-router-dom";

import { BaseQuestionnairePage } from "./BaseQuestionnairePage";
import { useEngagementQuestionnaire } from "./useEnagementQuestionnaire";

const EngagementQuestionnairePage: FunctionComponent = () => {
    const { engagementId, dynamicFormId } = useParams();

    const args = useEngagementQuestionnaire(engagementId || "", dynamicFormId || "");

    return <BaseQuestionnairePage {...args} />;
};

export default EngagementQuestionnairePage;
