import { FunctionComponent, useState } from "react";

import { Box, Button, Card, CardBody, CardHeader, Flex, Icon, SimpleGrid } from "@chakra-ui/react";
import { IconClipboardCopy, IconMessages } from "@tabler/icons-react";
import { useToast } from "am-tax-fe-core";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import ConversationDrawer from "../../components/ConversationDrawer";
import { RequestForm } from "../../components/RequestForm/RequestForm";
import { PLACEHOLDER_FOR_ID_NOT_CREATED } from "../../constants";
import { Page } from "../Page";

import { useRequestFormForEngagement } from "./useRequestFormForEngagement";

export const EngagementRequestPage: FunctionComponent = () => {
    const { engagementId, requestId } = useParams();
    const toast = useToast();
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation("EngagementRequestPage");
    function copyToClipboard() {
        navigator.clipboard.writeText(`${window.location}`);
        toast({
            title: "Link copied to clipboard",
            status: "info",
            duration: 2000,
            isClosable: true,
        });
    }

    const requestFormProps = useRequestFormForEngagement(engagementId ?? "", requestId ?? "");

    const hasValidRequestId = !!requestId && requestId !== PLACEHOLDER_FOR_ID_NOT_CREATED;

    const goBackToAllRequests = () => {
        requestFormProps.navigateBack();
    };

    const gutterMenu = (
        <SimpleGrid columns={{ base: 1, lg: 2 }} gap={3}>
            <Box>
                <Button variant="outline" onClick={goBackToAllRequests}>
                    {requestFormProps.navigateBackTitle}
                </Button>
            </Box>
        </SimpleGrid>
    );

    return (
        <Page title={`${requestFormProps.requestQuery.data?.requestId ? t("edit") : t("add")} ${t("request")}`} gutter={gutterMenu}>
            <Card>
                <CardHeader bgGradient="linear(to-br, purple.500, purple.300)"></CardHeader>
                <CardBody>
                    {hasValidRequestId && (
                        <Flex justify="space-between" mb="1rem">
                            <Button
                                rightIcon={<Icon as={IconClipboardCopy} />}
                                textAlign="right"
                                variant="ghost"
                                onClick={copyToClipboard}
                                marginRight={".75rem"}
                            >
                                {t("copyLink")}
                            </Button>
                            <Button rightIcon={<Icon as={IconMessages} />} textAlign="right" variant="outline" onClick={() => setIsOpen(!isOpen)}>
                                {t("conversations")}
                            </Button>
                        </Flex>
                    )}
                    <RequestForm {...requestFormProps} />
                </CardBody>
            </Card>
            <ConversationDrawer engagementId={engagementId ?? ""} requestId={requestId ?? ""} show={isOpen} toggleShow={setIsOpen} />
        </Page>
    );
};
