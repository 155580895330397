import { FunctionComponent } from "react";

import { useParams } from "react-router-dom";

import { useEngagementTaxNotices } from "../../api";

import { TaxNoticesPage } from "./TaxNoticesPage.tsx";

export const EngagementTaxNoticesPage: FunctionComponent = () => {
    const { engagementId } = useParams();
    const engagementTaxNoticesQuery = useEngagementTaxNotices(engagementId ?? "");

    return <TaxNoticesPage taxNoticesQuery={engagementTaxNoticesQuery} />;
};
