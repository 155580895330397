export enum EngagementContactType {
    ManagingDirector = 1,
    LeadDirector = 2,
    EngagementLead = 3,
    EngagementContributor = 4,
}

export enum NavDestination {
    Iframe = 1,
    Page = 2,
}

export enum NavArea {
    Engagement = 1,
    Top = 2,
    Bottom = 3,
}

export enum DeliverableStatusEnum {
    Requested = 0,
    Approved = 1,
    Rejected = 2,
}

export enum RequestStatusEnum {
    Requested = 1,
    Rejected = 2,
    Completed = 3,
}

export enum ApplicationName {
    VatSummary = 0,
}

export enum ImportType {
    VatSummary = 0,
}
