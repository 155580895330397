// @ts-ignore
import feCoreEnglish from "am-tax-fe-core/locales/en/am-tax-fe-core.json";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";

export const initializeI18n = async (languageCode: string) => {
    await i18n
        // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
        // learn more: https://github.com/i18next/i18next-http-backend
        // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
        .use(ChainedBackend)
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            fallbackLng: "en",
            debug: false,
            lng: languageCode,
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            backend: {
                backends: [
                    HttpBackend,
                    resourcesToBackend({
                        en: { "am-tax-fe-core": feCoreEnglish },
                    }),
                ],
                backendOptions: [
                    {
                        loadPath: "/locales/{{lng}}/{{ns}}.json",
                    },
                ],
            },
        });

    return i18n;
};
