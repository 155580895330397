import { FunctionComponent, useEffect, useMemo, useState } from "react";

import { Box, Button, Divider, VStack } from "@chakra-ui/react";
import { IconChevronRight } from "@tabler/icons-react";
import { Filter, Shimmer } from "am-tax-fe-core";

import { useClients } from "../../api";

export interface ClientListProps {
    clientId: string | undefined;
    onClientSelected: (customerId: string) => void;
}

export const ClientList: FunctionComponent<ClientListProps> = ({ clientId, onClientSelected }) => {
    const clientsQuery = useClients();
    const [filter, setFilter] = useState("");
    const clients = useMemo(() => clientsQuery.data || [], [clientsQuery.data]);
    const filteredClients = useMemo(() => {
        return filter.length ? clients.filter(client => client.clientName.toLowerCase().includes(filter.toLowerCase())) : clients;
    }, [clients, filter]);
    function updateFilter(value: string) {
        setFilter(value);
    }

    useEffect(() => {
        if (clients.length === 1 && !clientId) {
            onClientSelected(clients[0].clientId);
        }
    }, [clientId, clients, onClientSelected]);

    return (
        <VStack bgGradient={`linear(to-br, blue.700, blue.500)`} padding="2rem 0 2rem 2rem" spacing="1rem" alignItems="stretch" height="100%">
            <Box marginRight="2rem">
                <Filter placeholder="Filter Clients" onChange={updateFilter} count={{ total: clients.length, shown: filteredClients.length }} />
            </Box>

            {!clientsQuery.isLoading && clients.length === 0 && (
                <Box textAlign="center" color={"whiteAlpha.800"}>
                    No Clients Found
                </Box>
            )}

            <VStack
                spacing="1px"
                alignItems="stretch"
                overflowY="auto"
                divider={
                    <Box paddingRight="2rem" border={0}>
                        <Divider borderColor="blue.300" />
                    </Box>
                }
            >
                {clientsQuery.isLoading
                    ? Array(7)
                          .fill(null)
                          .map((val, i) => <Shimmer key={i} height="40px" mode={"dark"} marginRight="2rem" />)
                    : null}
                {filteredClients?.map(client => (
                    <Button
                        key={client.clientId}
                        rightIcon={client.clientId === clientId ? <IconChevronRight /> : <></>}
                        justifyContent="space-between"
                        onClick={() => onClientSelected(client.clientId)}
                        borderRadius={0}
                        paddingRight="2rem"
                        color={"whiteAlpha.900"}
                        _hover={{ bg: "whiteAlpha.400" }}
                        _active={{ bg: "blue.25", color: "blue.900" }}
                        isActive={client.clientId === clientId}
                        backgroundColor={"transparent"}
                        fontWeight={"normal"}
                    >
                        {client.clientName}
                    </Button>
                ))}
            </VStack>
        </VStack>
    );
};
