import { useMemo } from "react";

import { useNavigate } from "react-router-dom";

import { useCreateProjectFormData, useProjectFormData, useProjectFormDefinitions, useUpdateProjectFormData } from "../../api/questionnaires/api-queries.ts";
import { Mutation } from "../../utils/query-utils.ts";
import { QuestionnaireProps } from "../EngagementQuestionnairePage/BaseQuestionnairePage.tsx";

export function useProjectQuestionnaire(engagementId: string, projectId: string, dynamicFormDefinitionId: string): QuestionnaireProps {
    const { data: projectSchemas, isLoading: isLoadingFormDefintions } = useProjectFormDefinitions(engagementId, projectId);
    const formSchema = projectSchemas?.find(x => x.id === dynamicFormDefinitionId)?.schema;

    const { data: projSchemaData, isLoading: isLoadingFormData } = useProjectFormData(engagementId, dynamicFormDefinitionId, projectId);

    const createProjectFormData = useCreateProjectFormData();
    const createFormDataMutation = useMemo(() => new Mutation(createProjectFormData), [createProjectFormData]);

    const updateProjectFormData = useUpdateProjectFormData();
    const updateFormDataMutation = useMemo(() => new Mutation(updateProjectFormData), [updateProjectFormData]);

    const navigate = useNavigate();

    const goBack = () => {
        navigate(`/engagement/${engagementId}/project/${projectId}/questionnaires/`, { relative: "path" });
    };

    return {
        engagementId,
        projectId,
        dynamicFormDefinitionId,
        isLoadingFormDefintions,
        schema: formSchema,
        isLoadingFormData,
        dynamicFormResponse: projSchemaData,
        createFormDataMutation,
        updateFormDataMutation,
        goBack,
    };
}
