import { FunctionComponent } from "react";

import { Image, Text, VStack } from "@chakra-ui/react";

import Void from "../../assets/images/void.svg";
import { Page } from "../Page";

export const NotFoundPage: FunctionComponent = () => {
    return (
        <Page title={"Not Found"}>
            <VStack justify="center" direction={"column"}>
                <Image src={Void} boxSize="250px" mb={16} />

                <Text fontSize="18px" mt={3} mb={2} textTransform={"uppercase"}>
                    Page Not Found
                </Text>
                <Text color={"gray.500"} mb={6}>
                    The page you're looking for cannot be found.
                </Text>
            </VStack>
        </Page>
    );
};
