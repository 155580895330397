import { Option } from "am-tax-fe-core";
import { atom } from "jotai";

import { Project, ProjectSearchArgs } from "../api";
import { getLocalStorage, StorageKey } from "../utils/local-storage-utils";

export const calendarListViewAtom = atom<boolean>(getLocalStorage<boolean>(StorageKey.CalendarView) ?? false);
export const calendarMonthsPerPageAtom = atom<number>(getLocalStorage<number>(StorageKey.CalendarNumberOfMonths) ?? 6);

export const requestStatusChartViewAtom = atom<"piechart" | "barchart">(
    getLocalStorage<"piechart" | "barchart">(StorageKey.RequestStatusChartView) ?? "piechart",
);
export const progressChartViewAtom = atom<"piechart" | "barchart">(getLocalStorage<"piechart" | "barchart">(StorageKey.ProgessChartView) ?? "barchart");

export const clientIdAtom = atom<string | undefined>(undefined);
export const selectedIndexAtom = atom<number | undefined>(undefined);
export const isDeliverableRejectionModalOpenAtom = atom<boolean>(false);

export type ProjectSearchForm = ProjectSearchArgs & {
    leadMd?: Option<string>;
    leadDirector?: Option<string>;
};
export const projectSearchForm = atom<ProjectSearchForm>({});

export const engagementIdsLoadedAtom = atom<string[]>([]);
export const projectsAtom = atom<Project[]>([]);
export const showEngagementShadeAtom = atom<boolean>(false);
