import { FunctionComponent, ReactNode } from "react";

import { useParams } from "react-router-dom";

import { Permission, SecurityArea } from "../../auth";
import { useEngagementAppPermissions } from "../../hooks";

import { RouteGuard } from "./RouteGuard.tsx";

export interface RouteGuardProps {
    securityArea?: SecurityArea;
    hasAny?: Permission[];
    render: ReactNode;
}

export const EngagementRouteGuard: FunctionComponent<RouteGuardProps> = ({ securityArea, hasAny, render }) => {
    const { engagementId: engagementIdParam } = useParams();
    const appPermissions = useEngagementAppPermissions(engagementIdParam);

    if (!engagementIdParam) {
        throw new Error("EngagementRouteGuard: engagementId is required.");
    }

    return <RouteGuard appPermissions={appPermissions} securityArea={securityArea} hasAny={hasAny} render={render} />;
};
