import {
    applyParams,
    downloadFile,
    DownloadProgressCallback,
    FileContainer,
    getMiddlewareConfiguration,
    getQueryClient,
    process,
    removeTrailingSlash,
    saveMiddlewareConfiguration,
} from "am-tax-fe-core";
import createClient, { Middleware } from "openapi-fetch";
import type { MediaType } from "openapi-typescript-helpers";

import { components, paths } from "./generated/generated-api.ts";
import { queryKeys } from "./queryKeys.ts";
import { uploadFile } from "./upload-utils";

// declare fetcher for paths
let configured = false;
const defaultRegionId = 0;
let defaultBaseUrl = "";
type ApiClient<M extends MediaType = "application/json"> = ReturnType<typeof createClient<paths, M>>;
const apiClientsByRegion: Record<number, ApiClient> = {};

export function configureApiClient(baseUrl: string, middlewares?: Middleware[]) {
    saveMiddlewareConfiguration(middlewares);
    defaultBaseUrl = removeTrailingSlash(baseUrl);
    const defaultClient = createClient<paths, "application/json">({ baseUrl });
    if (middlewares) {
        defaultClient.use(...middlewares);
    }
    apiClientsByRegion[defaultRegionId] = defaultClient;
    configured = true;
}

export function getApiClientByRegion(region?: Region) {
    if (!configured) {
        throw new Error("You must call configureApiClient before making any api calls");
    }
    if (region) {
        let client = apiClientsByRegion[region.id];
        if (!client) {
            client = createClient<paths, "application/json">({ baseUrl: region.endpointUrl });
            client.use(...getMiddlewareConfiguration());
            apiClientsByRegion[region.id] = client;
        }
        return client;
    } else {
        return apiClientsByRegion[defaultRegionId]!;
    }
}

export function matchRegion(regionLookup: RegionLookup, args: { clientId: string; engagementId?: string; projectId?: string }): Region | undefined {
    const { clientId, engagementId, projectId } = args;
    let region: Region | undefined = undefined;
    if (clientId && regionLookup.clientEndpoints) {
        region = regionLookup.clientEndpoints[clientId];
    }
    if (engagementId && regionLookup.engagementEndpoints) {
        region = regionLookup.engagementEndpoints[engagementId] || region;
    }
    if (projectId && regionLookup.projectEndpoints) {
        region = regionLookup.projectEndpoints[projectId] || region;
    }

    return region;
}

async function getRegionMatch(args: { clientId: string; engagementId: string; projectId?: string }): Promise<Region | undefined> {
    const regionLookup: RegionLookup = (await getQueryClient().fetchQuery({
        ...queryKeys.regions.lookup,
        queryFn: getRegionLookup,
        gcTime: Infinity,
        staleTime: Infinity,
    })) as unknown as RegionLookup;

    return matchRegion(regionLookup, args);
}

export async function getEngagementRegion(args: GetEngagementArgs): Promise<Region | undefined> {
    const engagement: Engagement = (await getQueryClient().fetchQuery({
        ...queryKeys.engagements.byEngagementId(args.engagementId),
        queryFn: () => getEngagement(args.engagementId),
    })) as unknown as Engagement;
    return getRegionMatch(engagement);
}

export async function getProjectRegion(args: GetProjectArgs): Promise<Region | undefined> {
    const project: Project = (await getQueryClient().fetchQuery({
        ...queryKeys.projects.byProjectId(args.projectId),
        queryFn: () => getProject(args),
    })) as unknown as Project;
    return getRegionMatch(project);
}

export async function engagementApi<T>(args: GetEngagementArgs, callback: (apiClient: ApiClient) => Promise<T>): Promise<T> {
    const region = await getEngagementRegion(args);
    return callback(getApiClientByRegion(region));
}

export async function projectApi<T>(args: GetProjectArgs, callback: (apiClient: ApiClient) => Promise<T>): Promise<T> {
    const region = await getProjectRegion(args);
    return callback(getApiClientByRegion(region));
}

export function centralApi<T>(callback: (apiClient: ApiClient) => Promise<T>): Promise<T> {
    return callback(getApiClientByRegion());
}

export const getDisplayFormats = async (): Promise<DisplayFormats[]> => centralApi(client => process(client.GET("/api/displayformat/list")));
export type DisplayFormats = components["schemas"]["DisplayFormatResponse"];

export const getRegions = (): Promise<Region[]> => centralApi(client => process(client.GET(getRegionsPath)));
const getRegionsPath = "/api/Region";
export type Region = components["schemas"]["RegionResponse"];

export const getRegionLookup = async (): Promise<RegionLookup> => centralApi(client => process(client.GET(getRegionLookupPath)));
const getRegionLookupPath = "/api/Region/lookup";
export type RegionLookup = components["schemas"]["RegionLookupResponse"];

export const getRegionStorageLookup = async (): Promise<RegionStorageLookup> => centralApi(client => process(client.GET(getRegionStorageLookupPath)));
const getRegionStorageLookupPath = "/api/Region/storage/lookup";
export type RegionStorageLookup = components["schemas"]["RegionStorageLookupResponse"];
export type RegionStorageResponse = components["schemas"]["RegionStorageResponse"];

// Client
export const getClients = (): Promise<Client[]> => centralApi(client => process(client.GET("/api/client")));
export type Client = components["schemas"]["ClientResponse"];

export const getClient = (clientId: string): Promise<Client> =>
    centralApi(client => process(client.GET("/api/client/{clientId}", { params: { path: { clientId } } })));

// Engagement Summary
export const getEngagementSummary = (args: EngagementSummaryArgs): Promise<EngagementSummary> =>
    engagementApi(args, client => process(client.GET(getEngagementSummaryPath, { params: { path: args, query: args } })));
export type EngagementSummary = components["schemas"]["SummaryResponse"];
const getEngagementSummaryPath = "/api/engagement/{engagementId}/summary";
export type EngagementSummaryArgs = paths[typeof getEngagementSummaryPath]["get"]["parameters"]["path"] &
    paths[typeof getEngagementSummaryPath]["get"]["parameters"]["query"];

export type EngagementSummaryForRegionArgs = EngagementSummaryArgs & { projectId: string };
export const getEngagementSummaryForRegion = async (args: EngagementSummaryForRegionArgs): Promise<EngagementSummary> =>
    projectApi(args, client => process(client.GET(getEngagementSummaryPath, { params: { path: args, query: args } })));

// Project Summary
export const getProjectSummary = (args: ProjectSummaryArgs): Promise<ProjectSummary> =>
    projectApi(args, client => process(client.GET(getProjectSummaryPath, { params: { path: args } })));
export type ProjectSummary = components["schemas"]["SummaryResponse"];
const getProjectSummaryPath = "/api/engagement/{engagementId}/project/{projectId}/summary";
export type ProjectSummaryArgs = paths[typeof getProjectSummaryPath]["get"]["parameters"]["path"];

// Engagement Rollup
export const getEngagementRollup = async (): Promise<EngagementSummary[]> => centralApi(client => process(client.GET(engagementRollupPath)));
const engagementRollupPath = "/api/engagements/summary";

// Engagement
export const getEngagementsByClientId = async (clientId: string): Promise<ClientEngagementResponse> =>
    centralApi(client => process(client.GET("/api/client/{clientId}/engagements", { params: { path: { clientId } } })));
export type ClientEngagementResponse = components["schemas"]["ClientEngagementResponse"];
export type Engagement = components["schemas"]["EngagementResponse"];
export type AgressoEngagement = components["schemas"]["AgressEngagementResponse"];

export const createEngagement = async (args: PostEngagementArgs): Promise<Engagement> =>
    centralApi(client => process(client.POST("/api/engagement", { body: args })));
export type PostEngagementArgs = components["schemas"]["EngagementRequest"];

export const createCustomEngagement = async (args: PostCustomEngagementArgs): Promise<Engagement> =>
    centralApi(client => process(client.POST(createCustomEngagementPath, { body: args })));
const createCustomEngagementPath = "/api/customengagement";
export type PostCustomEngagementArgs = components["schemas"]["CustomEngagementRequest"];

export const updateEngagement = async (args: PutEngagementArgs): Promise<Engagement> =>
    centralApi(client => process(client.PUT(updateEngagementPath, { params: { path: args }, body: args })));
const updateEngagementPath = "/api/engagement/{engagementId}";
export type PutEngagementArgs = components["schemas"]["EngagementPutRequest"] & paths[typeof updateEngagementPath]["put"]["parameters"]["path"];

export const getEngagementTypes = async (): Promise<EngagementType[]> => centralApi(client => process(client.GET("/api/engagementtype")));
export type EngagementType = components["schemas"]["EngagementTypeResponse"];

export const getEngagement = async (engagementId: string): Promise<Engagement> =>
    centralApi(client => process(client.GET(getEngagementPath, { params: { path: { engagementId } } })));
const getEngagementPath = "/api/engagement/{engagementId}";
export type GetEngagementArgs = paths[typeof getEngagementPath]["get"]["parameters"]["path"];

export const requestAccessToEngagement = async (args: RequestAccessToEgagementArgs): Promise<unknown> =>
    centralApi(client => process(client.POST(requestAccessToEngagementPath, { params: { path: args } })));
const requestAccessToEngagementPath = "/api/user/engagement/{engagementId}/accessrequest";
export type RequestAccessToEgagementArgs = paths[typeof requestAccessToEngagementPath]["post"]["parameters"]["path"];

export const closeOrReopenEngagement = async (args: CloseOrReopenEngagementArgs): Promise<CloseOrReopenEngagementResponse> =>
    centralApi(client => process(client.POST(closeOrReopenEngagementPath, { params: { path: args } })));
const closeOrReopenEngagementPath = "/api/engagement/{engagementId}/activate";
export type CloseOrReopenEngagementArgs = paths[typeof closeOrReopenEngagementPath]["post"]["parameters"]["path"];
export type CloseOrReopenEngagementResponse = components["schemas"]["ToggleCloseEngagementResponse"];

// Project
export const getProjectsByEngagementId = async (engagementId: string): Promise<EngagementProjects> =>
    centralApi(client => process(client.GET("/api/engagement/{engagementId}/projects", { params: { path: { engagementId } } })));
export type EngagementProjects = components["schemas"]["EngagementProjectsResponse"];
export type Project = components["schemas"]["ProjectResponse"];
export type AgressoProject = components["schemas"]["AgressoProjectResponse"];

export const getProjectsByProjectCode = async (projectCode: string): Promise<EngagementProjects> =>
    centralApi(client => process(client.GET("/api/projectCode/{projectCode}/projects", { params: { path: { projectCode } } })));

export const getProjectTypes = async (): Promise<EngagementType[]> => centralApi(client => process(client.GET("/api/projecttype")));

export const createProject = async (args: PostProjectArgs): Promise<Project> =>
    centralApi(client => process(client.POST(createProjectPath, { params: { path: args }, body: args })));
const createProjectPath = "/api/engagement/{engagementId}/project";
export type PostProjectArgs = components["schemas"]["ProjectRequest"] & paths[typeof createProjectPath]["post"]["parameters"]["path"];

export const createNonProvisionedEngagementProject = async (args: PostProjectNpeArgs): Promise<Project> =>
    centralApi(client => process(client.POST(createNpeProjectPath, { body: args })));
const createNpeProjectPath = "/api/engagement/project";
export type PostProjectNpeArgs = components["schemas"]["ProjectRequest"];

export const createCustomProject = async (args: PostCustomProjectArgs): Promise<Project> =>
    centralApi(client => process(client.POST(createCustomProjectPath, { params: { path: args }, body: args })));
const createCustomProjectPath = "/api/engagement/{engagementId}/customproject";
export type PostCustomProjectArgs = components["schemas"]["CustomProjectRequest"] & paths[typeof createCustomProjectPath]["post"]["parameters"]["path"];

export const updateProject = async (args: PutProjectArgs): Promise<Project> =>
    centralApi(client => process(client.PUT(updateProjectPath, { params: { path: args }, body: args })));
const updateProjectPath = "/api/engagement/{engagementId}/project/{projectId}";
export type PutProjectArgs = components["schemas"]["ProjectPutRequest"] & paths[typeof updateProjectPath]["put"]["parameters"]["path"];

export const getProject = async (args: GetProjectArgs): Promise<Project> =>
    centralApi(client => process(client.GET(getProjectPath, { params: { path: args } })));
const getProjectPath = "/api/engagement/{engagementId}/project/{projectId}";
export type GetProjectArgs = paths[typeof getProjectPath]["get"]["parameters"]["path"];

export const requestAccessToProject = async (args: RequestAccessToProjectArgs): Promise<unknown> =>
    centralApi(client => process(client.POST(requestAccessToProjectPath, { params: { path: args } })));
const requestAccessToProjectPath = "/api/user/engagement/{engagementId}/project/{projectId}/accessrequest";
export type RequestAccessToProjectArgs = paths[typeof requestAccessToProjectPath]["post"]["parameters"]["path"];

export const closeOrReopenProject = async (args: CloseOrReopenProjectArgs): Promise<CloseOrReopenProjectResponse> =>
    centralApi(client => process(client.POST(closeOrReopenProjectPath, { params: { path: args } })));
const closeOrReopenProjectPath = "/api/engagement/{engagementId}/project/{projectId}/activate";
export type CloseOrReopenProjectArgs = paths[typeof closeOrReopenProjectPath]["post"]["parameters"]["path"];
export type CloseOrReopenProjectResponse = components["schemas"]["ToggleCloseProjectResponse"];

// Search Clients
export const searchClients = async (args: ClientSearchArgs): Promise<ClientSearchResponse[]> =>
    centralApi(client => process(client.GET(searchClientsPath, { params: { query: args } })));
export type ClientSearchResponse = components["schemas"]["ClientSearchResponse"];
const searchClientsPath = "/api/client/search";
export type ClientSearchArgs = NonNullable<paths[typeof searchClientsPath]["get"]["parameters"]["query"]>;

// Search Engagements
export const searchEngagements = async (args: EngagementSearchArgs): Promise<EngagementSearchResponse[]> =>
    centralApi(client => process(client.GET(searchEngagementsPath, { params: { query: args } })));
export type EngagementSearchResponse = components["schemas"]["EngagementSearchResponse"];
const searchEngagementsPath = "/api/engagement/search";
export type EngagementSearchArgs = NonNullable<paths[typeof searchEngagementsPath]["get"]["parameters"]["query"]>;

// Search Projects
export type ProjectSearchResponse = components["schemas"]["ProjectSearchResponse"];
export const searchProjects = async (args: ProjectSearchArgs): Promise<ProjectSearchResponse[]> =>
    centralApi(client => process(client.GET(searchProjectsPath, { params: { query: args } })));
const searchProjectsPath = "/api/project/search";
export type ProjectSearchArgs = NonNullable<paths[typeof searchProjectsPath]["get"]["parameters"]["query"]>;

//Search all engagements/projects
export type EngagementProjectResponseModel = components["schemas"]["EngagementProjectResponseModel"];
const getAllEngagementProjectsPath = "/api/user/engagement/project/search/userId/{userId}";
export type SearchEngagementProjectArgs = NonNullable<paths[typeof getAllEngagementProjectsPath]["get"]["parameters"]>;
export const searchEngagementProject = async (args: SearchEngagementProjectArgs): Promise<EngagementProjectResponseModel[]> =>
    centralApi(client => process(client.GET(getAllEngagementProjectsPath, { params: args })));

//Search user projects
export type UserProjectSummaryResponse = components["schemas"]["UserProjectSummaryResponse"];
const searchUserProjectPath = "/api/project/user/search";
export type SearchUserProjectsArgs = NonNullable<paths[typeof searchUserProjectPath]["get"]["parameters"]["query"]>;
export const searchUserProject = async (args: SearchUserProjectsArgs): Promise<UserProjectSummaryResponse[]> =>
    centralApi(client => process(client.GET(searchUserProjectPath, { params: { query: args } })));

// EngagementEvents
export const searchEngagementEvents = async (args: EngagementEventSearchArgs): Promise<EngagementEventSearchResults> =>
    engagementApi(args, client => process(client.GET(searchEngagementEventsPath, { params: { path: args, query: args } })));
const searchEngagementEventsPath = "/api/engagement/{engagementId}/event/search";
export type EngagementEventSearchResults = paths[typeof searchEngagementEventsPath]["get"]["responses"]["200"]["content"]["application/json"];
export type EngagementEventSearchParameters = paths[typeof searchEngagementEventsPath]["get"]["parameters"];
export type EngagementEventSearchArgs = EngagementEventSearchParameters["path"] & EngagementEventSearchParameters["query"];

export type EngagementEventSearchForRegionArgs = EngagementEventSearchArgs & { projectId: string };
export const searchEngagementEventsForRegion = async (args: EngagementEventSearchForRegionArgs): Promise<EngagementEventSearchResults> =>
    projectApi(args, client => process(client.GET(searchEngagementEventsPath, { params: { path: args, query: args } })));

export const getEngagementEvent = async (args: GetEngagementEventArgs): Promise<Event> =>
    engagementApi(args, client => process(client.GET(getEngagementEventPath, { params: { path: args } })));
const getEngagementEventPath = "/api/engagement/{engagementId}/event/{eventId}";
export type Event = components["schemas"]["EventResponse"];
export type GetEngagementEventArgs = paths[typeof getEngagementEventPath]["get"]["parameters"]["path"];

export const postEngagementEvent = async (args: PostEngagementEventArgs): Promise<Event> =>
    engagementApi(args, client => process(client.POST(postEngagementEventPath, { params: { path: args }, body: args })));
const postEngagementEventPath = "/api/engagement/{engagementId}/event";
export type EngagementEventRequest = components["schemas"]["EventRequest"];
export type PostEngagementEventArgs = paths[typeof postEngagementEventPath]["post"]["parameters"]["path"] & EngagementEventRequest;

export const putEngagementEvent = async (args: PutEngagementEventArgs): Promise<Event> =>
    engagementApi(args, client => process(client.PUT(putEngagementEventPath, { params: { path: args }, body: args })));
const putEngagementEventPath = "/api/engagement/{engagementId}/event/{eventId}";
export type PutEngagementEventArgs = paths[typeof putEngagementEventPath]["put"]["parameters"]["path"] & components["schemas"]["EventRequest"];

export const deleteEngagementEvent = async (args: DeleteEngagementEventArgs): Promise<unknown> =>
    engagementApi(args, client => process(client.DELETE(deleteEngagementEventPath, { params: { path: args } })));
const deleteEngagementEventPath = "/api/engagement/{engagementId}/event/{eventId}";
export type DeleteEngagementEventArgs = paths[typeof deleteEngagementEventPath]["delete"]["parameters"]["path"];

// ProjectEvents
export const searchProjectEvents = async (args: ProjectEventSearchArgs): Promise<ProjectEventSearchResults> =>
    projectApi(args, client => process(client.GET(searchProjectEventsPath, { params: { path: args, query: args } })));
const searchProjectEventsPath = "/api/engagement/{engagementId}/project/{projectId}/event/search";
export type ProjectEventSearchResults = paths[typeof searchProjectEventsPath]["get"]["responses"]["200"]["content"]["application/json"];
export type ProjectEventSearchParameters = paths[typeof searchProjectEventsPath]["get"]["parameters"];
export type ProjectEventSearchArgs = ProjectEventSearchParameters["path"] & ProjectEventSearchParameters["query"];

export const getProjectEvent = async (args: GetProjectEventArgs): Promise<Event> =>
    projectApi(args, client => process(client.GET(getProjectEventPath, { params: { path: args } })));
const getProjectEventPath = "/api/engagement/{engagementId}/project/{projectId}/event/{eventId}";
export type GetProjectEventArgs = paths[typeof getProjectEventPath]["get"]["parameters"]["path"];

export const postProjectEvent = async (args: PostProjectEventArgs): Promise<Event> =>
    projectApi(args, client => process(client.POST(postProjectEventPath, { params: { path: args }, body: args })));
const postProjectEventPath = "/api/engagement/{engagementId}/project/{projectId}/event";
export type ProjectEventRequest = components["schemas"]["EventRequest"];
export type PostProjectEventArgs = paths[typeof postProjectEventPath]["post"]["parameters"]["path"] & ProjectEventRequest;

export const putProjectEvent = async (args: PutProjectEventArgs): Promise<Event> =>
    projectApi(args, client => process(client.PUT(putProjectEventPath, { params: { path: args }, body: args })));
const putProjectEventPath = "/api/engagement/{engagementId}/project/{projectId}/event/{eventId}";
export type PutProjectEventArgs = paths[typeof putProjectEventPath]["put"]["parameters"]["path"] & components["schemas"]["EventRequest"];

export const deleteProjectEvent = async (args: DeleteProjectEventArgs): Promise<unknown> =>
    projectApi(args, client => process(client.DELETE(deleteProjectEventPath, { params: { path: args } })));
const deleteProjectEventPath = "/api/engagement/{engagementId}/project/{projectId}/event/{eventId}";
export type DeleteProjectEventArgs = paths[typeof deleteProjectEventPath]["delete"]["parameters"]["path"];

// Engagement Requests
export const getEngagementRequestsByEngagementId = async (args: GetEngagementRequestsByEngagementIdArgs): Promise<Request[]> =>
    engagementApi(args, client => process(client.GET(getEngagementRequestsByEngagementIdPath, { params: { path: args, query: args } })));
const getEngagementRequestsByEngagementIdPath = "/api/engagement/{engagementId}/requests";
export type GetEngagementRequestsByEngagementIdArgs = paths[typeof getEngagementRequestsByEngagementIdPath]["get"]["parameters"]["path"] &
    paths[typeof getEngagementRequestsByEngagementIdPath]["get"]["parameters"]["query"];
export type Request = components["schemas"]["RequestResponse"];
export type Document = components["schemas"]["DocumentResponse"];

export type GetEngagementRequestsByEngagementIdForRegionArgs = GetEngagementRequestsByEngagementIdArgs & { projectId: string };
export const getEngagementRequestsByEngagementIdForRegion = async (args: GetEngagementRequestsByEngagementIdForRegionArgs): Promise<Request[]> =>
    projectApi(args, client => process(client.GET(getEngagementRequestsByEngagementIdPath, { params: { path: args, query: args } })));

export const getEngagementRequest = async (args: GetEngagementRequestArgs): Promise<Request> =>
    engagementApi(args, client => process(client.GET(getEngagementRequestPath, { params: { path: args } })));
const getEngagementRequestPath = "/api/engagement/{engagementId}/request/{requestId}";
export type GetEngagementRequestArgs = paths[typeof getEngagementRequestPath]["get"]["parameters"]["path"];

export const postEngagementRequest = async (args: PostEngagementRequestArgs): Promise<Request> =>
    engagementApi(args, client => process(client.POST(postEngagementRequestPath, { params: { path: args }, body: args })));
const postEngagementRequestPath = "/api/engagement/{engagementId}/request";
export type PostEngagementRequestArgs = components["schemas"]["RequestModel"] & { engagementId: string };

export const putEngagementRequest = async (args: PutEngagementRequestArgs): Promise<Request> =>
    engagementApi(args, client => process(client.PUT(putEngagementRequestPath, { params: { path: args }, body: args })));
const putEngagementRequestPath = "/api/engagement/{engagementId}/request/{requestId}";
export type PutEngagementRequestArgs = PostEngagementRequestArgs & { requestId: string };

export const deleteEngagementRequest = async (args: DeleteEngagementRequestArgs): Promise<unknown> =>
    engagementApi(args, client => process(client.DELETE(deleteEngagementRequestPath, { params: { path: args } })));
const deleteEngagementRequestPath = "/api/engagement/{engagementId}/request/{requestId}";
export type DeleteEngagementRequestArgs = { engagementId: string; requestId: string };

export type UploadDocumentsToEngagementRequestArgs = {
    engagementId: string;
    requestId: string;
    fileContainer: FileContainer;
};
export const uploadDocumentToEngagementRequest = async (args: UploadDocumentsToEngagementRequestArgs): Promise<Document> => {
    const region = await getEngagementRegion(args);
    return uploadFile(region?.endpointUrl || defaultBaseUrl, `/api/engagement/${args.engagementId}/request/${args.requestId}/document`, args.fileContainer);
};

export type UploadDocumentsToFeedbackArgs = {
    feedbackId: string;
    fileContainer: FileContainer;
};
export const uploadDocumentToFeedback = async (args: UploadDocumentsToFeedbackArgs) => {
    return uploadFile(defaultBaseUrl, `/api/feedback/${args.feedbackId}/document`, args.fileContainer);
};

// Project Requests
export const getProjectRequestsByProjectId = async (args: GetProjectRequestsByProjectIdArgs): Promise<Request[]> =>
    projectApi(args, client => process(client.GET(getProjectRequestsByProjectIdPath, { params: { path: args } })));
const getProjectRequestsByProjectIdPath = "/api/engagement/{engagementId}/project/{projectId}/requests";
export type GetProjectRequestsByProjectIdArgs = paths[typeof getProjectRequestsByProjectIdPath]["get"]["parameters"]["path"];

export const getProjectRequest = async (args: GetProjectRequestArgs): Promise<Request> =>
    projectApi(args, client => process(client.GET(getProjectRequestPath, { params: { path: args } })));
const getProjectRequestPath = "/api/engagement/{engagementId}/project/{projectId}/request/{requestId}";
export type GetProjectRequestArgs = paths[typeof getProjectRequestPath]["get"]["parameters"]["path"];

export const postProjectRequest = async (args: PostProjectRequestArgs): Promise<Request> =>
    projectApi(args, client => process(client.POST(postProjectRequestPath, { params: { path: args }, body: args })));
const postProjectRequestPath = "/api/engagement/{engagementId}/project/{projectId}/request";
export type PostProjectRequestArgs = components["schemas"]["RequestModel"] & { engagementId: string; projectId: string };

export const putProjectRequest = async (args: PutProjectRequestArgs): Promise<Request> =>
    projectApi(args, client => process(client.PUT(putProjectRequestPath, { params: { path: args }, body: args })));
const putProjectRequestPath = "/api/engagement/{engagementId}/project/{projectId}/request/{requestId}";
export type PutProjectRequestArgs = PostProjectRequestArgs & { requestId: string };

export const deleteProjectRequest = async (args: DeleteProjectRequestArgs): Promise<unknown> =>
    projectApi(args, client => process(client.DELETE(deleteProjectRequestPath, { params: { path: args } })));
const deleteProjectRequestPath = "/api/engagement/{engagementId}/project/{projectId}/request/{requestId}";
export type DeleteProjectRequestArgs = { engagementId: string; projectId: string; requestId: string };

export type UploadDocumentsToProjectRequestArgs = UploadDocumentsToEngagementRequestArgs & {
    projectId: string;
};
export const uploadDocumentToProjectRequest = async (args: UploadDocumentsToProjectRequestArgs): Promise<Document> => {
    const region = await getProjectRegion(args);
    return uploadFile(
        region?.endpointUrl || defaultBaseUrl,
        `/api/engagement/${args.engagementId}/project/${args.projectId}/request/${args.requestId}/document`,
        args.fileContainer,
    );
};

// Engagement Documents
export type DocumentsResponse = components["schemas"]["EngagementDocumentsResponse"];

export const getEngagementDocuments = async (args: GetEngagementDocumentsArgs): Promise<DocumentsResponse> =>
    engagementApi(args, client => process(client.GET(getEngagementDocumentsPath, { params: { path: args } })));
const getEngagementDocumentsPath = "/api/engagement/{engagementId}/root/{rootFolderId}/folder/{folderId}";
export type GetEngagementDocumentsArgs = paths[typeof getEngagementDocumentsPath]["get"]["parameters"]["path"];

export const deleteEngagementDocument = async (args: DeleteEngagementDocumentArgs): Promise<unknown> =>
    engagementApi(args, client => process(client.DELETE(deleteEngagementDocumentPath, { params: { path: args } })));
const deleteEngagementDocumentPath = "/api/engagement/{engagementId}/document/{documentId}";
export type DeleteEngagementDocumentArgs = paths[typeof deleteEngagementDocumentPath]["delete"]["parameters"]["path"];

export const downloadEngagementDocument = async (args: DownloadEngagementDocumentArgs): Promise<Blob> => _downloadEngagementDocument(args);
const downloadEngagementDocumentPath = "/api/engagement/{engagementId}/document/{documentId}/version/{documentVersionId}/content";
export type DownloadEngagementDocumentArgs = paths[typeof downloadEngagementDocumentPath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};
const _downloadEngagementDocument = async (args: DownloadEngagementDocumentArgs): Promise<Blob> => {
    const region = await getEngagementRegion(args);
    const response = await downloadFile(region?.endpointUrl || defaultBaseUrl, applyParams(downloadEngagementDocumentPath, args), args.progressCallback);
    if (response.ok) {
        return response.blob();
    } else {
        throw new Error("The document could not be downloaded.");
    }
};

export const createEngagementFolders = async (args: CreateEngagementFoldersArgs): Promise<Record<string, string>> =>
    engagementApi(args, client => process(client.POST(commonEngagementFoldersApiPath, { params: { path: args }, body: args.folderPaths })));
const commonEngagementFoldersApiPath = "/api/engagement/{engagementId}/folder/{folderId}";
export type CreateEngagementFoldersArgs = paths[typeof commonEngagementFoldersApiPath]["post"]["parameters"]["path"] & { folderPaths: string[] };

export const renameEngagementFolder = async (args: RenameEngagementFolderArgs): Promise<unknown> =>
    engagementApi(args, client => process(client.PUT(commonEngagementFoldersApiPath, { params: { path: args }, body: args })));
export type Folder = components["schemas"]["FolderModel"];
export type RenameEngagementFolderArgs = paths[typeof commonEngagementFoldersApiPath]["put"]["parameters"]["path"] & Folder;

export const deleteEngagementFolder = async (args: DeleteEngagementFolderArgs): Promise<unknown> =>
    engagementApi(args, client => process(client.DELETE(commonEngagementFoldersApiPath, { params: { path: args } })));
export type DeleteEngagementFolderArgs = paths[typeof commonEngagementFoldersApiPath]["delete"]["parameters"]["path"];

export const uploadEngagementDocument = async (args: UploadEngagementDocumentArgs): Promise<Document> => {
    const region = await getEngagementRegion(args);
    return uploadFile(region?.endpointUrl || defaultBaseUrl, applyParams(uploadEngagementDocumentPath, args), args.fileContainer);
};
const uploadEngagementDocumentPath = "/api/engagement/{engagementId}/folder/{folderId}/document";
export type UploadEngagementDocumentArgs = paths[typeof uploadEngagementDocumentPath]["post"]["parameters"]["path"] & { fileContainer: FileContainer };

export type PreviewLinkModel = components["schemas"]["PreviewLinkModel"];
export const getEngagementDocumentPreviewLink = async (args: GetEngagementDocumentPreviewLinkArgs): Promise<PreviewLinkModel> =>
    engagementApi(args, client => process(client.GET("/api/engagement/{engagementId}/document/{documentId}/previewlink", { params: { path: args } })));
export type GetEngagementDocumentPreviewLinkArgs = { engagementId: string; documentId: string };

export const getEngagementBoxFolderLink = async (args: GetEngagementBoxFolderLinkArgs): Promise<PreviewLinkModel> =>
    engagementApi(args, client => process(client.GET("/api/engagement/{engagementId}/folder/{folderId}/link", { params: { path: args } })));
export type GetEngagementBoxFolderLinkArgs = { engagementId: string; folderId: string };

// Project Documents
export const getProjectDocuments = async (args: GetProjectDocumentsArgs): Promise<DocumentsResponse> =>
    projectApi(args, client => process(client.GET(getProjectDocumentsPath, { params: { path: args } })));
const getProjectDocumentsPath = "/api/engagement/{engagementId}/project/{projectId}/root/{rootFolderId}/folder/{folderId}";
export type GetProjectDocumentsArgs = paths[typeof getProjectDocumentsPath]["get"]["parameters"]["path"];

export const deleteProjectDocument = async (args: DeleteProjectDocumentArgs): Promise<unknown> =>
    projectApi(args, client => process(client.DELETE(deleteProjectDocumentPath, { params: { path: args } })));
const deleteProjectDocumentPath = "/api/engagement/{engagementId}/project/{projectId}/document/{documentId}";
export type DeleteProjectDocumentArgs = paths[typeof deleteProjectDocumentPath]["delete"]["parameters"]["path"];

export const downloadProjectDocument = async (args: DownloadProjectDocumentArgs): Promise<Blob> => _downloadProjectDocument(args);
const downloadProjectDocumentPath = "/api/engagement/{engagementId}/project/{projectId}/document/{documentId}/version/{documentVersionId}/content";
export type DownloadProjectDocumentArgs = paths[typeof downloadProjectDocumentPath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};
const _downloadProjectDocument = async (args: DownloadProjectDocumentArgs): Promise<Blob> => {
    const region = await getProjectRegion(args);
    const response = await downloadFile(region?.endpointUrl || defaultBaseUrl, applyParams(downloadProjectDocumentPath, args), args.progressCallback);
    if (response.ok) {
        return response.blob();
    } else {
        throw new Error("The document could not be downloaded.");
    }
};

export const createProjectFolders = async (args: CreateProjectFoldersArgs): Promise<Record<string, string>> =>
    projectApi(args, client => process(client.POST(commonProjectFoldersApiPath, { params: { path: args }, body: args.folderPaths })));
const commonProjectFoldersApiPath = "/api/engagement/{engagementId}/project/{projectId}/folder/{folderId}";
export type CreateProjectFoldersArgs = paths[typeof commonProjectFoldersApiPath]["post"]["parameters"]["path"] & { folderPaths: string[] };

export const renameProjectFolder = async (args: RenameProjectFolderArgs): Promise<unknown> =>
    projectApi(args, client => process(client.PUT(commonProjectFoldersApiPath, { params: { path: args }, body: args })));
export type RenameProjectFolderArgs = paths[typeof commonProjectFoldersApiPath]["put"]["parameters"]["path"] & Folder;

export const deleteProjectFolder = async (args: DeleteProjectFolderArgs): Promise<unknown> =>
    projectApi(args, client => process(client.DELETE(commonProjectFoldersApiPath, { params: { path: args } })));
export type DeleteProjectFolderArgs = paths[typeof commonProjectFoldersApiPath]["delete"]["parameters"]["path"];

export const uploadProjectDocument = async (args: UploadProjectDocumentArgs): Promise<Document> => {
    const region = await getProjectRegion(args);
    return uploadFile(region?.endpointUrl || defaultBaseUrl, applyParams(uploadProjectDocumentPath, args), args.fileContainer);
};
const uploadProjectDocumentPath = "/api/engagement/{engagementId}/project/{projectId}/folder/{folderId}/document";
export type UploadProjectDocumentArgs = paths[typeof uploadProjectDocumentPath]["post"]["parameters"]["path"] & { fileContainer: FileContainer };

export const getProjectDocumentPreviewLink = async (args: GetProjectDocumentPreviewLinkArgs): Promise<PreviewLinkModel> =>
    projectApi(args, client =>
        process(client.GET("/api/engagement/{engagementId}/project/{projectId}/document/{documentId}/previewlink", { params: { path: args } })),
    );
export type GetProjectDocumentPreviewLinkArgs = { engagementId: string; projectId: string; documentId: string };

export const getProjectBoxFolderLink = async (args: GetProjectBoxFolderLinkArgs): Promise<PreviewLinkModel> =>
    projectApi(args, client => process(client.GET("/api/engagement/{engagementId}/project/{projectId}/folder/{folderId}/link", { params: { path: args } })));
export type GetProjectBoxFolderLinkArgs = { engagementId: string; projectId: string; folderId: string };

// Bulk Event Templates
export const downloadEngagementEventTemplate = async (args: DownloadEngagementEventTemplateArgs): Promise<Blob> => _downloadEngagementEventTemplate(args);
const downloadEngagementEventTemplatePath = "/api/engagement/{engagementId}/event/template/download";
export type DownloadEngagementEventTemplateArgs = paths[typeof downloadEngagementEventTemplatePath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};
const _downloadEngagementEventTemplate = async (args: DownloadEngagementEventTemplateArgs): Promise<Blob> => {
    const region = await getEngagementRegion(args);
    const response = await downloadFile(region?.endpointUrl || defaultBaseUrl, applyParams(downloadEngagementEventTemplatePath, args), args.progressCallback);
    if (response.ok) {
        return response.blob();
    } else {
        throw new Error("The file could not be downloaded.");
    }
};

export const downloadProjectEventTemplate = async (args: DownloadProjectEventTemplateArgs): Promise<Blob> => _downloadProjectEventTemplate(args);
const downloadProjectEventTemplatePath = "/api/engagement/{engagementId}/project/{projectId}/event/template/download";
export type DownloadProjectEventTemplateArgs = paths[typeof downloadProjectEventTemplatePath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};
const _downloadProjectEventTemplate = async (args: DownloadProjectEventTemplateArgs): Promise<Blob> => {
    const region = await getProjectRegion(args);
    const response = await downloadFile(region?.endpointUrl || defaultBaseUrl, applyParams(downloadProjectEventTemplatePath, args), args.progressCallback);
    if (response.ok) {
        return response.blob();
    } else {
        throw new Error("The file could not be downloaded.");
    }
};

// Bulk Request Templates
export const downloadEngagementRequestTemplate = async (args: DownloadEngagementRequestTemplateArgs): Promise<Blob> => _downloadEngagementRequestTemplate(args);
const downloadEngagementRequestTemplatePath = "/api/engagement/{engagementId}/request/template/download";
export type DownloadEngagementRequestTemplateArgs = paths[typeof downloadEngagementRequestTemplatePath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};
const _downloadEngagementRequestTemplate = async (args: DownloadEngagementRequestTemplateArgs): Promise<Blob> => {
    const region = await getEngagementRegion(args);
    const response = await downloadFile(region?.endpointUrl || defaultBaseUrl, applyParams(downloadEngagementRequestTemplatePath, args), args.progressCallback);
    if (response.ok) {
        return response.blob();
    } else {
        throw new Error("The file could not be downloaded.");
    }
};

export const downloadProjectRequestTemplate = async (args: DownloadProjectRequestTemplateArgs): Promise<Blob> => _downloadProjectRequestTemplate(args);
const downloadProjectRequestTemplatePath = "/api/engagement/{engagementId}/project/{projectId}/request/template/download";
export type DownloadProjectRequestTemplateArgs = paths[typeof downloadProjectRequestTemplatePath]["get"]["parameters"]["path"] & {
    progressCallback: DownloadProgressCallback;
};
const _downloadProjectRequestTemplate = async (args: DownloadProjectRequestTemplateArgs): Promise<Blob> => {
    const region = await getProjectRegion(args);
    const response = await downloadFile(region?.endpointUrl || defaultBaseUrl, applyParams(downloadProjectRequestTemplatePath, args), args.progressCallback);
    if (response.ok) {
        return response.blob();
    } else {
        throw new Error("The file could not be downloaded.");
    }
};

//Bulk Upload Requests
export const bulkUploadEngagementRequests = async (args: BulkUploadEngagementRequestsArgs): Promise<unknown> => {
    const region = await getEngagementRegion(args);
    return uploadFile(region?.endpointUrl || defaultBaseUrl, applyParams(bulkUploadEngagementRequestsPath, args), args.fileContainer);
};
const bulkUploadEngagementRequestsPath = "/api/engagement/{engagementId}/request/template/upload";
export type BulkUploadEngagementRequestsArgs = paths[typeof bulkUploadEngagementRequestsPath]["post"]["parameters"]["path"] & {
    fileContainer: FileContainer;
};

export const bulkUploadProjectRequests = async (args: BulkUploadProjectRequestsArgs): Promise<unknown> => {
    const region = await getProjectRegion(args);
    return uploadFile(region?.endpointUrl || defaultBaseUrl, applyParams(bulkUploadProjectRequestsPath, args), args.fileContainer);
};
const bulkUploadProjectRequestsPath = "/api/engagement/{engagementId}/project/{projectId}/request/template/upload";
export type BulkUploadProjectRequestsArgs = paths[typeof bulkUploadProjectRequestsPath]["post"]["parameters"]["path"] & {
    fileContainer: FileContainer;
};

//Bulk Upload Events
export const bulkUploadEngagementEvents = async (args: BulkUploadEngagementEventsArgs): Promise<unknown> => {
    const region = await getEngagementRegion(args);
    return uploadFile(region?.endpointUrl || defaultBaseUrl, applyParams(bulkUploadEngagementEventsPath, args), args.fileContainer);
};
const bulkUploadEngagementEventsPath = "/api/engagement/{engagementId}/event/template/upload";
export type BulkUploadEngagementEventsArgs = paths[typeof bulkUploadEngagementEventsPath]["post"]["parameters"]["path"] & {
    fileContainer: FileContainer;
};

export const bulkUploadProjectEvents = async (args: BulkUploadProjectEventsArgs): Promise<unknown> => {
    const region = await getProjectRegion(args);
    return uploadFile(region?.endpointUrl || defaultBaseUrl, applyParams(bulkUploadProjectEventsPath, args), args.fileContainer);
};
const bulkUploadProjectEventsPath = "/api/engagement/{engagementId}/project/{projectId}/event/template/upload";
export type BulkUploadProjectEventsArgs = paths[typeof bulkUploadProjectEventsPath]["post"]["parameters"]["path"] & {
    fileContainer: FileContainer;
};

// States
export const getStates = async (): Promise<State[]> => centralApi(client => process(client.GET("/api/states")));
export type State = components["schemas"]["USStateResponse"];

// Request Statuses
export const getRequestStatuses = async (): Promise<RequestStatus[]> => centralApi(client => process(client.GET("/api/engagement/request/requeststatuses")));
export type RequestStatus = components["schemas"]["RequestStatusResponse"];

export const getRequestTypes = async (): Promise<RequestTypes[]> => centralApi(client => process(client.GET("/api/engagement/request/requestTypes")));
export type RequestTypes = components["schemas"]["RequestTypeResponse"];

// Engagement Activities
export const getEngagementActivityTypes = async (args: GetEngagementActivityTypesArgs): Promise<ActivityType[]> =>
    centralApi(client => process(client.GET(getEngagementActivityTypesPath, { params: { path: args } })));
const getEngagementActivityTypesPath = "/api/engagement/{engagementId}/activitytype";
export type GetEngagementActivityTypesArgs = paths[typeof getEngagementActivityTypesPath]["get"]["parameters"]["path"];
export type ActivityType = components["schemas"]["ActivityTypeResponse"];

export const searchEngagementActivities = async (args: EngagementActivitySearchArgs): Promise<ActivitySearchResponse> =>
    engagementApi(args, client => process(client.GET(searchEngagementActivitiesPath, { params: { path: args, query: args } })));
export type ActivitySearchResponse = components["schemas"]["ActivityResponse"];
export type Activity = components["schemas"]["SingleActivityResponse"];
const searchEngagementActivitiesPath = "/api/engagement/{engagementId}/activity";
type SearchEngagementActivitiesParamsType = paths[typeof searchEngagementActivitiesPath]["get"]["parameters"];
export type EngagementActivitySearchArgs = SearchEngagementActivitiesParamsType["path"] & SearchEngagementActivitiesParamsType["query"];

// Project Activities
export const getProjectActivityTypes = async (args: GetProjectActivityTypesArgs): Promise<ActivityType[]> =>
    centralApi(client => process(client.GET(getEngagementActivityTypesPath, { params: { path: args } })));
const getProjectActivityTypesPath = "/api/engagement/{engagementId}/project/{projectId}/activitytype";
export type GetProjectActivityTypesArgs = paths[typeof getProjectActivityTypesPath]["get"]["parameters"]["path"];

export const searchProjectActivities = async (args: ProjectActivitySearchArgs): Promise<ActivitySearchResponse> =>
    projectApi(args, client => process(client.GET(searchProjectActivitiesPath, { params: { path: args, query: args } })));
const searchProjectActivitiesPath = "/api/engagement/{engagementId}/project/{projectId}/activity";
type SearchProjectActivitiesParamsType = paths[typeof searchProjectActivitiesPath]["get"]["parameters"];
export type ProjectActivitySearchArgs = SearchProjectActivitiesParamsType["path"] & SearchProjectActivitiesParamsType["query"];

// Nav Items
export const getNavItems = async (): Promise<NavItemResponse[]> => centralApi(client => process(client.GET(getNavItemsPath)));
export type NavItemResponse = components["schemas"]["NavItemResponse"];
const getNavItemsPath = "/api/navitems";

export const getNavItemsByEngagement = async (args: GetNavItemsByEngagemenArgs): Promise<NavItemResponse[]> =>
    centralApi(client => process(client.GET(getNavItemsByEngagementPath, { params: { path: args } })));
const getNavItemsByEngagementPath = "/api/engagement/{engagementId}/navitems";
export type GetNavItemsByEngagemenArgs = paths[typeof getNavItemsByEngagementPath]["get"]["parameters"]["path"];

export const getNavItemsByProject = async (args: GetNavItemsByProjectArgs): Promise<NavItemResponse[]> =>
    centralApi(client => process(client.GET(getNavItemsByProjectPath, { params: { path: args } })));
const getNavItemsByProjectPath = "/api/engagement/{engagementId}/project/{projectId}/navitems";
export type GetNavItemsByProjectArgs = paths[typeof getNavItemsByProjectPath]["get"]["parameters"]["path"];

// Roles
export const getRoles = async (): Promise<Role[]> => centralApi(client => process(client.GET("/api/user/roles")));
export type Role = components["schemas"]["RoleResponse"];

export const getMyRoleOnEngagement = async (args: GetMyRoleOnEngagementArgs): Promise<Role> =>
    centralApi(client => process(client.GET(getMyRoleOnEngagementPath, { params: { path: args } })));
const getMyRoleOnEngagementPath = "/api/user/engagement/{engagementId}/role";
export type GetMyRoleOnEngagementArgs = paths[typeof getMyRoleOnEngagementPath]["get"]["parameters"]["path"];

export const getMyRoleOnProject = async (args: GetMyRoleOnProjectArgs): Promise<Role> =>
    centralApi(client => process(client.GET(getMyRoleOnProjectPath, { params: { path: args } })));
const getMyRoleOnProjectPath = "/api/user/engagement/{engagementId}/project/{projectId}/role";
export type GetMyRoleOnProjectArgs = paths[typeof getMyRoleOnProjectPath]["get"]["parameters"]["path"];

// Permissions
export const getPermissionsByRole = async (): Promise<PermissionsByRoleResponse> => centralApi(client => process(client.GET(getPermissionsByRolePath)));
const getPermissionsByRolePath = "/api/permissions";
export type PermissionsByRoleResponse = {
    [key: string]: string[] | undefined;
};

// Users
export const getCurrentUser = async (): Promise<User> => centralApi(client => process(client.GET("/api/user")));
export type User = components["schemas"]["UserResponse"];

export const searchUsers = async (args: NonNullable<SearchUsersArgs>): Promise<User[]> =>
    centralApi(client => process(client.GET(searchUsersPath, { params: { query: args } })));
const searchUsersPath = "/api/user/search";
export type SearchUsersArgs = NonNullable<paths[typeof searchUsersPath]["get"]["parameters"]["query"]>;
export enum UserSearchLevel {
    LMD = "LMD",
    LD = "LD",
}

// GDPR Consent
export const getGdprConsent = async (): Promise<GdprConsent> => centralApi(client => process(client.GET("/api/gdpr/consent")));
export type GdprConsent = components["schemas"]["GdprConsent"];

export const saveGdprConsent = async (args: GdprConsent): Promise<unknown> => centralApi(client => process(client.POST("/api/gdpr/consent", { body: args })));

// Dashboard (Engagement & Projects) Users
export type Participant = components["schemas"]["ParticipantResponse"];
export const getDashboardUsers = async (args: DashboardUsersArgs): Promise<Participant[]> =>
    centralApi(client => process(client.GET(getDashboardUsersPath, { params: { path: args, query: args } })));
const getDashboardUsersPath = "/api/user/engagement/{engagementId}/dashboardusers";
export type DashboardUsersArgs = paths[typeof getEngagementUsersPath]["get"]["parameters"]["path"] &
    paths[typeof getDashboardUsersPath]["get"]["parameters"]["query"];

// Engagement Users
export const getEngagementUsers = async (args: GetEngagementUsersArgs): Promise<Participant[]> =>
    centralApi(client => process(client.GET(getEngagementUsersPath, { params: { path: args } })));
const getEngagementUsersPath = "/api/user/engagement/{engagementId}";
export type GetEngagementUsersArgs = paths[typeof getEngagementUsersPath]["get"]["parameters"]["path"];

export const deleteUserFromEngagement = async (args: DeleteUserFromEngagementArgs): Promise<unknown> =>
    centralApi(client => process(client.DELETE(deleteUserFromEngagementPath, { params: { path: args } })));
const deleteUserFromEngagementPath = "/api/user/engagement/{engagementId}/user/{userId}";
export type DeleteUserFromEngagementArgs = paths[typeof deleteUserFromEngagementPath]["delete"]["parameters"]["path"];

export const addExternalUserToEngagement = async (args: AddExternalUserToEngagementArgs): Promise<User> =>
    centralApi(client => process(client.POST(addExternalUserPath, { params: { path: args }, body: args })));
const addExternalUserPath = "/api/user/engagement/{engagementId}/externaluser";
export type ExternalUser = components["schemas"]["ExternalUser"];
export type AddExternalUserToEngagementArgs = paths[typeof addExternalUserPath]["post"]["parameters"]["path"] & ExternalUser;

export const addOrUpdateUserOnEngagement = async (args: AddUpdateUserOnEngagementArgs): Promise<unknown> =>
    centralApi(client => process(client.PUT(addUpdateUserOnEngagementPath, { params: { path: args }, body: args.participants })));
const addUpdateUserOnEngagementPath = "/api/user/engagement/{engagementId}";
export type ParticipantModel = components["schemas"]["ParticipantModel"];
export type AddUpdateUserOnEngagementArgs = paths[typeof addUpdateUserOnEngagementPath]["put"]["parameters"]["path"] & {
    participants: Array<ParticipantModel>;
};

// Project Users
export const getProjectUsers = async (args: GetProjectUsersArgs): Promise<Participant[]> =>
    centralApi(client => process(client.GET(getProjectUsersPath, { params: { path: args } })));
const getProjectUsersPath = "/api/user/engagement/{engagementId}/project/{projectId}";
export type GetProjectUsersArgs = paths[typeof getProjectUsersPath]["get"]["parameters"]["path"];

export const deleteUserFromProject = async (args: DeleteUserFromProjectArgs): Promise<unknown> =>
    centralApi(client => process(client.DELETE(deleteUserFromProjectPath, { params: { path: args } })));
const deleteUserFromProjectPath = "/api/user/engagement/{engagementId}/project/{projectId}/user/{userId}";
export type DeleteUserFromProjectArgs = paths[typeof deleteUserFromProjectPath]["delete"]["parameters"]["path"];

export const addExternalUserToProject = async (args: AddExternalUserToProjectArgs): Promise<User> =>
    centralApi(client => process(client.POST(addExternalUserToProjectPath, { params: { path: args }, body: args })));
const addExternalUserToProjectPath = "/api/user/engagement/{engagementId}/project/{projectId}/externaluser";
export type AddExternalUserToProjectArgs = paths[typeof addExternalUserToProjectPath]["post"]["parameters"]["path"] & ExternalUser;

export const addOrUpdateUserOnProject = async (args: AddUpdateUserOnProjectArgs): Promise<unknown> =>
    centralApi(client => process(client.PUT(addUpdateUserOnProjectPath, { params: { path: args }, body: args.participants })));
const addUpdateUserOnProjectPath = "/api/user/engagement/{engagementId}/project/{projectId}";
export type AddUpdateUserOnProjectArgs = paths[typeof addUpdateUserOnProjectPath]["put"]["parameters"]["path"] & {
    participants: Array<ParticipantModel>;
};

export type UpdateUserRoleArgs = AddUpdateUserOnEngagementArgs & { projectId?: string };
export async function updateUserRole(args: UpdateUserRoleArgs) {
    if (args.projectId) {
        return addOrUpdateUserOnProject(args as AddUpdateUserOnProjectArgs);
    } else {
        return addOrUpdateUserOnEngagement(args);
    }
}

// Feature Flags
export const getFeatureFlags = async (): Promise<FeatureFlagResponse> => centralApi(client => process(client.GET(getFeatureFlagsPath)));
export type FeatureFlagResponse = { [key: string]: string | undefined };
const getFeatureFlagsPath = "/api/features";

// Global Notification Settings
export const getNotificationOptions = async (): Promise<Array<NotificationGroupOptionResponse>> =>
    centralApi(client => process(client.GET(getNotificationOptionsPath)));
export type NotificationGroupOptionResponse = components["schemas"]["NotificationGroupOptionResponse"];
const getNotificationOptionsPath = "/api/notification/options";

//Engagement Notification Settings
export const getEngagementNotificationSettings = async (args: GetEngagementNotificationSettingsArgs): Promise<NotificationSettings> =>
    engagementApi(args, client => process(client.GET(engagementNotificationSettingsPath, { params: { path: args } })));
export type NotificationSettings = components["schemas"]["NotificationSettingsResponse"];
const engagementNotificationSettingsPath = "/api/engagement/{engagementId}/notification/settings";
export type GetEngagementNotificationSettingsArgs = paths[typeof engagementNotificationSettingsPath]["get"]["parameters"]["path"];

export const updateEngagementNotificationSettings = async (args: UpdateEngagementNotificationSettingArgs): Promise<NotificationSettings> =>
    engagementApi(args, client => process(client.PATCH(engagementNotificationSettingsPath, { params: { path: args }, body: args })));
export type NotificationSettingModel = components["schemas"]["NotificationSettingsRequestModel"]["settings"];
export type NotificationsEnabledModel = components["schemas"]["NotificationSettingsRequestModel"]["isNotificationEnabled"];
export type UpdateEngagementNotificationSettingArgs = paths[typeof engagementNotificationSettingsPath]["patch"]["parameters"]["path"] & {
    settings: NotificationSettingModel;
    isNotificationEnabled: NotificationsEnabledModel;
};

//Project Notification Settings
export const getProjectNotificationSettings = async (args: GetProjectNotificationSettingsArgs): Promise<NotificationSettings> =>
    projectApi(args, client => process(client.GET(projectNotificationSettingsPath, { params: { path: args } })));
const projectNotificationSettingsPath = "/api/engagement/{engagementId}/project/{projectId}/notification/settings";
export type GetProjectNotificationSettingsArgs = paths[typeof projectNotificationSettingsPath]["get"]["parameters"]["path"];

export const updateProjectNotificationSettings = async (args: UpdateProjectNotificationSettingArgs): Promise<NotificationSettings> =>
    projectApi(args, client => process(client.PATCH(projectNotificationSettingsPath, { params: { path: args }, body: args })));
export type UpdateProjectNotificationSettingArgs = paths[typeof projectNotificationSettingsPath]["patch"]["parameters"]["path"] & {
    settings: NotificationSettingModel;
    isNotificationEnabled: NotificationsEnabledModel;
};

/** START REQUEST-CONVERSATIONS */

export type ConversationRequest = components["schemas"]["ConversationRequest"];
export type ConversationResponse = components["schemas"]["RequestConversationResponse"];
export type CommentRequest = components["schemas"]["CommentRequest"];
export type CommentResponse = components["schemas"]["RequestCommentResponse"];
export type RequestCommentModel = components["schemas"]["RequestCommentModel"];

// GET engagement request conversations
const getEngagementRequestConversations = async (args: GetEngagementRequestConversationsArgs): Promise<ConversationResponse[]> =>
    engagementApi(args, client => process(client.GET(engagementRequestConversationsPath, { params: { path: args } })));
const engagementRequestConversationsPath = "/api/engagement/{engagementId}/request/{requestId}/conversations";
type GetEngagementRequestConversationsArgs = paths[typeof engagementRequestConversationsPath]["get"]["parameters"]["path"];

// GET project request conversations
const getProjectRequestConversations = async (args: GetProjectRrequestConversationsArgs): Promise<ConversationResponse[]> =>
    projectApi(args, client => process(client.GET(projectRequestConversationsPath, { params: { path: args } })));
const projectRequestConversationsPath = "/api/engagement/{engagementId}/project/{projectId}/request/{requestId}/conversations";
type GetProjectRrequestConversationsArgs = paths[typeof projectRequestConversationsPath]["get"]["parameters"]["path"];

export async function getRequestConversations(requestId: string, engagementId: string, projectId?: string) {
    if (projectId) {
        return getProjectRequestConversations({ engagementId, requestId, projectId });
    } else {
        return getEngagementRequestConversations({ engagementId, requestId });
    }
}

// POST create conversation on engagement
const createNewEngangementConversation = async (args: CreateNewEngagementConversationArgs): Promise<ConversationResponse> =>
    engagementApi(args, client => process(client.POST(engangementRequestConversationPath, { params: { path: args }, body: args })));
const engangementRequestConversationPath = "/api/engagement/{engagementId}/request/{requestId}/conversation";
type CreateNewEngagementConversationArgs = paths[typeof engangementRequestConversationPath]["post"]["parameters"]["path"] & ConversationRequest;

// POST create conversation on project
const createNewProjectConversation = async (args: CreateNewProjectConversationArgs): Promise<ConversationResponse> =>
    projectApi(args, client => process(client.POST(projectRequestConversationPath, { params: { path: args }, body: args })));
const projectRequestConversationPath = "/api/engagement/{engagementId}/project/{projectId}/request/{requestId}/conversation";
type CreateNewProjectConversationArgs = paths[typeof projectRequestConversationPath]["post"]["parameters"]["path"] & ConversationRequest;

export type CreateRequestConversationArgs = CreateNewEngagementConversationArgs & { projectId?: string };
export async function createRequestConversation(args: CreateRequestConversationArgs) {
    if (args.projectId) {
        return createNewProjectConversation(args as CreateNewProjectConversationArgs);
    } else {
        return createNewEngangementConversation(args);
    }
}

// DELETE remove conversation on engagement
const deleteEngangementConversation = async (args: DeleteEngagementConversationArgs): Promise<unknown> =>
    engagementApi(args, client => process(client.DELETE(deleteEngangementRequestConversationPath, { params: { path: args } })));
const deleteEngangementRequestConversationPath = "/api/engagement/{engagementId}/conversation/{conversationId}";
type DeleteEngagementConversationArgs = paths[typeof deleteEngangementRequestConversationPath]["delete"]["parameters"]["path"];

// DELETE remove conversation on project
const deleteProjectConversation = async (args: DeleteProjectConversationArgs): Promise<unknown> =>
    projectApi(args, client => process(client.DELETE(deleteProjectRequestConversationPath, { params: { path: args } })));
const deleteProjectRequestConversationPath = "/api/engagement/{engagementId}/project/{projectId}/conversation/{conversationId}";
type DeleteProjectConversationArgs = paths[typeof deleteProjectRequestConversationPath]["delete"]["parameters"]["path"];

export type DeleteRequestConversationArgs = DeleteEngagementConversationArgs & { requestId: string; projectId?: string };
export async function deleteRequestConversation(args: DeleteRequestConversationArgs) {
    if (args.projectId) {
        return deleteProjectConversation(args as DeleteProjectConversationArgs);
    } else {
        return deleteEngangementConversation(args);
    }
}

export type DeleteUserArgs = DeleteUserFromEngagementArgs & { projectId?: string };
export async function deleteUser(args: DeleteUserArgs) {
    if (args.projectId) {
        return deleteUserFromProject(args as DeleteUserFromProjectArgs);
    } else {
        return deleteUserFromEngagement(args);
    }
}

// POST create conversation comment on engagement
const createNewEngangementConversationComment = async (args: CreateNewEngagementConversationCommentArgs): Promise<CommentResponse> =>
    engagementApi(args, client => process(client.POST(engangementRequestConversationCommentPath, { params: { path: args }, body: args })));
const engangementRequestConversationCommentPath = "/api/engagement/{engagementId}/request/{requestId}/conversation/{conversationId}/comment";
type CreateNewEngagementConversationCommentArgs = paths[typeof engangementRequestConversationCommentPath]["post"]["parameters"]["path"] & CommentRequest;

// POST create conversation comment on project
const createNewProjectConversationComment = async (args: CreateNewProjectConversationCommentArgs): Promise<CommentResponse> =>
    projectApi(args, client => process(client.POST(projectRequestConversationCommentPath, { params: { path: args }, body: args })));
const projectRequestConversationCommentPath = "/api/engagement/{engagementId}/project/{projectId}/request/{requestId}/conversation/{conversationId}/comment";
type CreateNewProjectConversationCommentArgs = paths[typeof projectRequestConversationCommentPath]["post"]["parameters"]["path"] & CommentRequest;

export type CreateRequestCommentArgs = CreateNewEngagementConversationCommentArgs & { projectId?: string };
export async function createRequestComment(args: CreateRequestCommentArgs) {
    if (args.projectId) {
        return createNewProjectConversationComment(args as CreateNewProjectConversationCommentArgs);
    } else {
        return createNewEngangementConversationComment(args);
    }
}

// DELETE remove conversation comment on engagement
const deleteEngangementConversationComment = async (args: DeleteEngagementConversationCommentArgs): Promise<unknown> =>
    engagementApi(args, client => process(client.DELETE(deleteEngangementRequestConversationCommentPath, { params: { path: args } })));
const deleteEngangementRequestConversationCommentPath = "/api/engagement/{engagementId}/comment/{commentId}";
type DeleteEngagementConversationCommentArgs = paths[typeof deleteEngangementRequestConversationCommentPath]["delete"]["parameters"]["path"];

// DELETE remove conversation comment on project
const deleteProjectConversationComment = async (args: DeleteProjectConversationCommentArgs): Promise<unknown> =>
    projectApi(args, client => process(client.DELETE(deleteProjectRequestConversationCommentPath, { params: { path: args } })));
const deleteProjectRequestConversationCommentPath = "/api/engagement/{engagementId}/project/{projectId}/comment/{commentId}";
type DeleteProjectConversationCommentArgs = paths[typeof deleteProjectRequestConversationCommentPath]["delete"]["parameters"]["path"];

export type DeleteRequestCommentArgs = DeleteEngagementConversationCommentArgs & { requestId: string; projectId?: string };
export async function deleteRequestComment(args: DeleteRequestCommentArgs) {
    if (args.projectId) {
        return deleteProjectConversationComment(args as DeleteProjectConversationCommentArgs);
    } else {
        return deleteEngangementConversationComment(args);
    }
}

export const publishEngagementConversation = async (args: PublishEngagementConversationArgs): Promise<unknown> =>
    engagementApi(args, client => process(client.PUT(publishEngagementConversationPath, { params: { path: args } })));
const publishEngagementConversationPath = "/api/publish/engagement/{engagementId}/conversation/{conversationId}";
export type PublishEngagementConversationArgs = paths[typeof publishEngagementConversationPath]["put"]["parameters"]["path"];

export const unpublishEngagementConversation = async (args: PublishEngagementConversationArgs): Promise<unknown> =>
    engagementApi(args, client => process(client.PUT(unpublishEngagementConversationPath, { params: { path: args } })));
const unpublishEngagementConversationPath = "/api/unpublish/engagement/{engagementId}/conversation/{conversationId}";

export const publishProjectConversation = async (args: PublishProjectConversationArgs): Promise<unknown> =>
    projectApi(args, client => process(client.PUT(publishProjectConversationPath, { params: { path: args } })));
const publishProjectConversationPath = "/api/publish/engagement/{engagementId}/project/{projectId}/conversation/{conversationId}";
export type PublishProjectConversationArgs = paths[typeof publishProjectConversationPath]["put"]["parameters"]["path"];

export const unpublishProjectConversation = async (args: PublishProjectConversationArgs): Promise<unknown> =>
    projectApi(args, client => process(client.PUT(unpublishProjectConversationPath, { params: { path: args } })));
const unpublishProjectConversationPath = "/api/unpublish/engagement/{engagementId}/project/{projectId}/conversation/{conversationId}";

export type PublishRequestConversationArgs = PublishEngagementConversationArgs & { requestId: string; projectId?: string; publish: boolean };
export async function togglePublishConversation(args: PublishRequestConversationArgs) {
    if (args.publish) {
        if (args.projectId) {
            return publishProjectConversation(args as PublishProjectConversationArgs);
        } else {
            return publishEngagementConversation(args);
        }
    } else {
        if (args.projectId) {
            return unpublishProjectConversation(args as PublishProjectConversationArgs);
        } else {
            return unpublishEngagementConversation(args);
        }
    }
}

export const publishEngagementComment = async (args: PublishEngagementCommentArgs): Promise<unknown> =>
    engagementApi(args, client => process(client.PUT(publishEngagementCommentPath, { params: { path: args } })));
const publishEngagementCommentPath = "/api/publish/engagement/{engagementId}/comment/{commentId}";
export type PublishEngagementCommentArgs = paths[typeof publishEngagementCommentPath]["put"]["parameters"]["path"];

export const unpublishEngagementComment = async (args: PublishEngagementCommentArgs): Promise<unknown> =>
    engagementApi(args, client => process(client.PUT(unpublishEngagementCommentPath, { params: { path: args } })));
const unpublishEngagementCommentPath = "/api/unpublish/engagement/{engagementId}/comment/{commentId}";

export const publishProjectComment = async (args: PublishProjectCommentArgs): Promise<unknown> =>
    projectApi(args, client => process(client.PUT(publishProjectCommentPath, { params: { path: args } })));
const publishProjectCommentPath = "/api/publish/engagement/{engagementId}/project/{projectId}/comment/{commentId}";
export type PublishProjectCommentArgs = paths[typeof publishProjectCommentPath]["put"]["parameters"]["path"];

export const unpublishProjectComment = async (args: PublishProjectCommentArgs): Promise<unknown> =>
    projectApi(args, client => process(client.PUT(unpublishProjectCommentPath, { params: { path: args } })));
const unpublishProjectCommentPath = "/api/unpublish/engagement/{engagementId}/project/{projectId}/comment/{commentId}";

export type PublishRequestCommentArgs = PublishEngagementCommentArgs & { requestId: string; projectId?: string; publish: boolean };
export async function togglePublishComment(args: PublishRequestCommentArgs) {
    if (args.publish) {
        if (args.projectId) {
            return publishProjectComment(args as PublishProjectCommentArgs);
        } else {
            return publishEngagementComment(args);
        }
    } else {
        if (args.projectId) {
            return unpublishProjectComment(args as PublishProjectCommentArgs);
        } else {
            return unpublishEngagementComment(args);
        }
    }
}
/** STOP REQUEST-CONVERSATIONS */
export type ProjectReportArgs = paths[typeof getProjectPath]["get"]["parameters"]["path"];
export type EngagementReportArgs = paths[typeof getEngagementPath]["get"]["parameters"]["path"];
const updateProjectReportPath = "/api/Report/engagement/{engagementId}/project/{projectId}";
const updateEngagementReportPath = "/api/Report/engagement/{engagementId}";
type GetReportsArgs = { engagementId: string; projectId: string | undefined };
export type UpdateProjectReportsArgs = ProjectReportArgs & Report;
export type UpdateEngagementReportsArgs = EngagementReportArgs & Report;

export const getProjectReports = async (args: ProjectReportArgs): Promise<Report[]> =>
    projectApi(args, client => process(client.GET(projectReportsPath, { params: { path: args } })));
export type Report = components["schemas"]["ReportModel"];
const projectReportsPath = "/api/Report/engagement/{engagementId}/project/{projectId}";

export const getEngagementReports = async (args: EngagementReportArgs): Promise<Report[]> =>
    engagementApi(args, client => process(client.GET(engagementReportsPath, { params: { path: args } })));
const engagementReportsPath = "/api/Report/engagement/{engagementId}";

export const createProjectWorkspace = async (args: CreateProjectWorkspaceArgs): Promise<Workspace> =>
    projectApi(args, client => process(client.POST(createProjectWorkspacePath, { params: { path: { ...args, projectId: args.projectId || "" } } })));
export type Workspace = components["schemas"]["WorkspaceModel"];
const createProjectWorkspacePath = "/api/Report/engagement/{engagementId}/project/{projectId}/group/create";
export type CreateProjectWorkspaceArgs = ProjectReportArgs & { workspaceName: string };

export const createEngagementWorkspace = async (args: CreateEngagementWorkspaceArgs): Promise<Workspace> =>
    engagementApi(args, client => process(client.POST(createWorkspacePath, { params: { path: { ...args, engagementId: args.engagementId } } })));
const createWorkspacePath = "/api/Report/engagement/{engagementId}/group/create";
export type CreateEngagementWorkspaceArgs = EngagementReportArgs & { workspaceName: string };

export const updateEngagementReport = async (args: UpdateEngagementReportsArgs): Promise<Report> =>
    engagementApi(args, client => process(client.PUT(updateEngagementReportPath, { params: { path: args }, body: args })));

export const updateProjectReport = async (args: UpdateProjectReportsArgs): Promise<Report> =>
    projectApi(args, client => process(client.PUT(updateProjectReportPath, { params: { path: args }, body: args })));

export const getProjectWorkspace = async (args: GetProjectWorkspaceArgs): Promise<Workspace> =>
    projectApi(args, client => process(client.GET(getProjectWorkspacePath, { params: { path: args } })));
const getProjectWorkspacePath = "/api/engagement/{engagementId}/project/{projectId}/workspace";
export type GetProjectWorkspaceArgs = { engagementId: string; projectId: string };

export const getEngagementWorkspace = async (args: GetEngagementWorkspaceArgs): Promise<Workspace> =>
    engagementApi(args, client => process(client.GET(getEngagementWorkspacePath, { params: { path: args } })));
const getEngagementWorkspacePath = "/api/engagement/{engagementId}/workspace";
export type GetEngagementWorkspaceArgs = { engagementId: string };

export const createProjectReport = async (args: UpdateProjectReportsArgs): Promise<Report> =>
    projectApi(args, client => process(client.POST(updateProjectReportPath, { params: { path: { ...args, projectId: args.projectId } }, body: args })));

export const createEngagementReport = async (args: UpdateEngagementReportsArgs): Promise<Report> =>
    engagementApi(args, client => process(client.POST(updateEngagementReportPath, { params: { path: args }, body: args })));

export const removeReport = async (args: UpdateEngagementReportsArgs): Promise<Report> =>
    engagementApi(args, client => process(client.DELETE(updateEngagementReportPath, { params: { path: args }, body: args })));

export const authReport = async (args: AuthReportsArgs): Promise<ReportAuthToken> =>
    centralApi(client => process(client.POST(authReportPath, { params: { path: { ...args, projectId: args.projectId || "" } }, body: args })));
export type ReportAuthToken = components["schemas"]["ReportAuthToken"];
export type GetReportAuthorizationQuery = components["schemas"]["GetReportAuthorizationQuery"];
const authReportPath = "/api/Report/auth/engagement/{engagementId}/project/{projectId}";
export type AuthReportsArgs = GetReportsArgs & GetReportAuthorizationQuery;

export type EngagementReportFileArgs = {
    engagementId: string;
    reportId: string;
    fileContainer: FileContainer;
};
export const uploadEngagementReportFile = async (args: EngagementReportFileArgs): Promise<Document> => {
    const region = await getEngagementRegion(args);
    return uploadFile(region?.endpointUrl || defaultBaseUrl, `/api/Report/engagement/${args.engagementId}/report/${args.reportId}/file`, args.fileContainer);
};

export type ProjectReportFileArgs = {
    engagementId: string;
    projectId: string;
    reportId: string;
    fileContainer: FileContainer;
};
export const uploadProjectReportFile = async (args: ProjectReportFileArgs): Promise<Document> => {
    const region = await getProjectRegion(args);
    return uploadFile(
        region?.endpointUrl || defaultBaseUrl,
        `/api/Report/engagement/${args.engagementId}/project/${args.projectId}/report/${args.reportId}/file`,
        args.fileContainer,
    );
};

const updatePhoneNumberPath = "/api/user/{userId}/phone";
type PhoneNumberModel = components["schemas"]["PhoneNumberModel"];
type UpdatePhoneNumberArgs = paths[typeof updatePhoneNumberPath]["put"]["parameters"]["path"] & PhoneNumberModel;
export const updatePhoneNumber = async (args: UpdatePhoneNumberArgs): Promise<PhoneNumberModel> =>
    centralApi(client => process(client.PUT(updatePhoneNumberPath, { params: { path: { ...args, userId: args.userId || "" } }, body: args })));

export type TreeNode = components["schemas"]["TreeNode"];
type GetEngagementFolderStructureArgs = { engagementId: string };
const getEngagementFolderStructurePath = "/api/folder/engagement/{engagementId}";
export const getEngagementFolderStructure = async (args: GetEngagementFolderStructureArgs): Promise<TreeNode[]> =>
    centralApi(client => process(client.GET(getEngagementFolderStructurePath, { params: { path: { ...args } } })));

type GetProjectFolderStructureArgs = { engagementId: string; projectId: string };
const getProjectFolderStructurePath = "/api/folder/engagement/{engagementId}/project/{projectId}";
export const getProjectFolderStructure = async (args: GetProjectFolderStructureArgs): Promise<TreeNode[]> =>
    centralApi(client => process(client.GET(getProjectFolderStructurePath, { params: { path: { ...args } } })));

export type ApplicationArgs = { engagementId: string; projectId: string | undefined };
const applicationPath = "/api/Application/engagement/{engagementId}/project/{projectId}";
export type Application = components["schemas"]["ApplicationModel"];

export const getApplications = async (args: ApplicationArgs): Promise<Application[]> =>
    centralApi(client => process(client.GET(applicationPath, { params: { path: { ...args, projectId: args.projectId || "" } } })));

export const enableApplication = async (args: ApplicationArgs & Application): Promise<Application> => {
    const path = { engagementId: args.engagementId, projectId: args.projectId || "" };
    const params = { path };
    return centralApi(client => process(client.POST(applicationPath, { params, body: args })));
};

export const updateApplication = async (args: ApplicationArgs & Application): Promise<unknown> => {
    const path = { engagementId: args.engagementId, projectId: args.projectId || "" };
    const params = { path };
    return centralApi(client => process(client.PUT(applicationPath, { params, body: args })));
};

export type UploadDocumentsToEngagementDeliverableArgs = {
    engagementId: string;
    deliverableId: string;
    fileContainer: FileContainer;
};
export const uploadDocumentToEngagementDeliverable = async (args: UploadDocumentsToEngagementDeliverableArgs): Promise<Document> => {
    const region = await getEngagementRegion(args);
    return uploadFile(
        region?.endpointUrl || defaultBaseUrl,
        `/api/engagement/${args.engagementId}/deliverable/${args.deliverableId}/document`,
        args.fileContainer,
    );
};

export type UploadDocumentsToProjectDeliverableArgs = UploadDocumentsToEngagementDeliverableArgs & {
    projectId: string;
};
export const uploadDocumentToProjectDeliverable = async (args: UploadDocumentsToProjectDeliverableArgs): Promise<Document> => {
    const region = await getProjectRegion(args);
    return uploadFile(
        region?.endpointUrl || defaultBaseUrl,
        `/api/engagement/${args.engagementId}/project/${args.projectId}/deliverable/${args.deliverableId}/document`,
        args.fileContainer,
    );
};
const engagementApproversListPath = "/api/Deliverable/engagement/{engagementId}/approverlist";
const projectApproversListPath = "/api/Deliverable/engagement/{engagementId}/project/{projectId}/approverlist";
export type GetEngagementApproversListArgs = paths[typeof engagementApproversListPath]["get"]["parameters"]["path"];
export type GetProjectApproversListArgs = paths[typeof projectApproversListPath]["get"]["parameters"]["path"];

export const getEngagementApproversList = async (args: GetEngagementApproversListArgs): Promise<User[]> =>
    engagementApi(args, client => process(client.GET(engagementApproversListPath, { params: { path: args } })));
export const getProjectApproversList = async (args: GetProjectApproversListArgs): Promise<User[]> =>
    projectApi(args, client => process(client.GET(projectApproversListPath, { params: { path: args } })));

export const getApproversList = async (engagementId: string, projectId?: string | undefined): Promise<User[]> => {
    if (projectId) {
        return getProjectApproversList({ engagementId, projectId });
    } else {
        return getEngagementApproversList({ engagementId });
    }
};

//Engagement Tax Notices
export type TaxNotice = components["schemas"]["TaxNoticeResponse"];

export const getEngagementTaxNotices = async (args: GetEngagementTaxNoticesArgs): Promise<TaxNotice[]> =>
    engagementApi(args, client => process(client.GET(getEngagementTaxNoticesPath, { params: { path: args } })));
const getEngagementTaxNoticesPath = "/api/engagement/{engagementId}/taxnotice";
export type GetEngagementTaxNoticesArgs = paths[typeof getEngagementTaxNoticesPath]["get"]["parameters"]["path"];

export const getEngagementTaxNotice = async (args: GetEngagementTaxNoticeArgs): Promise<TaxNotice> =>
    engagementApi(args, client => process(client.GET(getEngagementTaxNoticePath, { params: { path: args } })));
const getEngagementTaxNoticePath = "/api/engagement/{engagementId}/taxnotice/{taxNoticeId}";
export type GetEngagementTaxNoticeArgs = paths[typeof getEngagementTaxNoticePath]["get"]["parameters"]["path"];

export type PostEngagementTaxNoticeArgs = components["schemas"]["TaxNoticeRequest"] & { engagementId: string };
const createEngagementTaxNoticePath = "/api/engagement/{engagementId}/taxnotice";
export const createEngagementTaxNotice = async (args: PostEngagementTaxNoticeArgs): Promise<TaxNotice> =>
    engagementApi(args, client => process(client.POST(createEngagementTaxNoticePath, { params: { path: args }, body: args })));

export type PutEngagementTaxNoticeArgs = components["schemas"]["TaxNoticeRequest"] & { engagementId: string; taxNoticeId: string };
const updateEngagementTaxNoticePath = "/api/engagement/{engagementId}/taxnotice/{taxNoticeId}";
export const updateEngagementTaxNotice = async (args: PutEngagementTaxNoticeArgs): Promise<TaxNotice> =>
    engagementApi(args, client => process(client.PUT(updateEngagementTaxNoticePath, { params: { path: args }, body: args })));

export type DeleteEngagementTaxNoticeArgs = { engagementId: string; taxNoticeId: string };
const deleteTaxNoticePath = "/api/taxnotice/{taxNoticeId}";
export const deleteEngagementTaxNotice = async (args: DeleteEngagementTaxNoticeArgs): Promise<number> =>
    engagementApi(args, client => process(client.DELETE(deleteTaxNoticePath, { params: { path: args } })));

export type UploadDocumentsToEngagementTaxNoticeArgs = {
    engagementId: string;
    taxNoticeId: string;
    fileContainer: FileContainer;
};
export const uploadDocumentToEngagementTaxNotice = async (args: UploadDocumentsToEngagementTaxNoticeArgs): Promise<Document> => {
    const region = await getEngagementRegion(args);
    return uploadFile(region?.endpointUrl || defaultBaseUrl, `/api/engagement/${args.engagementId}/taxnotice/${args.taxNoticeId}/document`, args.fileContainer);
};

//Project Tax Notices
export const getProjectTaxNotices = async (args: GetProjectTaxNoticesArgs): Promise<TaxNotice[]> =>
    projectApi(args, client => process(client.GET(getProjectTaxNoticesPath, { params: { path: args } })));
const getProjectTaxNoticesPath = "/api/engagement/{engagementId}/project/{projectId}/taxnotice";
export type GetProjectTaxNoticesArgs = paths[typeof getProjectTaxNoticesPath]["get"]["parameters"]["path"];

export const getProjectTaxNotice = async (args: GetProjectTaxNoticeArgs): Promise<TaxNotice> =>
    projectApi(args, client => process(client.GET(getProjectTaxNoticePath, { params: { path: args } })));
const getProjectTaxNoticePath = "/api/engagement/{engagementId}/project/{projectId}/taxnotice/{taxNoticeId}";
export type GetProjectTaxNoticeArgs = paths[typeof getProjectTaxNoticePath]["get"]["parameters"]["path"];

export type PostProjectTaxNoticeArgs = components["schemas"]["TaxNoticeRequest"] & { engagementId: string; projectId: string };
const postProjectTaxNoticePath = "/api/engagement/{engagementId}/project/{projectId}/taxnotice";
export const createProjectTaxNotice = async (args: PostProjectTaxNoticeArgs): Promise<TaxNotice> =>
    projectApi(args, client => process(client.POST(postProjectTaxNoticePath, { params: { path: args }, body: args })));

export type PutProjectTaxNoticeArgs = components["schemas"]["TaxNoticeRequest"] & { engagementId: string; projectId: string; taxNoticeId: string };
const updateProjectTaxNoticePath = "/api/engagement/{engagementId}/project/{projectId}/taxnotice/{taxNoticeId}";
export const updateProjectTaxNotice = async (args: PutProjectTaxNoticeArgs): Promise<TaxNotice> =>
    projectApi(args, client => process(client.PUT(updateProjectTaxNoticePath, { params: { path: args }, body: args })));

export type UploadDocumentsToProjectTaxNoticeArgs = UploadDocumentsToEngagementTaxNoticeArgs & {
    projectId: string;
};
export const uploadDocumentToProjectTaxNotice = async (args: UploadDocumentsToProjectTaxNoticeArgs): Promise<Document> => {
    const region = await getProjectRegion(args);
    return uploadFile(
        region?.endpointUrl || defaultBaseUrl,
        `/api/engagement/${args.engagementId}/project/${args.projectId}/taxnotice/${args.taxNoticeId}/document`,
        args.fileContainer,
    );
};

export type DeleteProjectTaxNoticeArgs = { engagementId: string; projectId: string; taxNoticeId: string };
export const deleteProjectTaxNotice = async (args: DeleteProjectTaxNoticeArgs): Promise<number> =>
    projectApi(args, client => process(client.DELETE(deleteTaxNoticePath, { params: { path: args } })));

const feedbackPath = "/api/feedback";
export type FeedbackArgs = components["schemas"]["ApplicationFeedbackModel"];
export type FeedbackResponse = components["schemas"]["ApplicationFeedbackResponse"];
export const createFeedback = async (args: FeedbackArgs): Promise<FeedbackResponse> => centralApi(client => process(client.POST(feedbackPath, { body: args })));

// Vat Summary
export const importApplicationDataToEngagement = async (engagementId: string, applicationName: number, fileContainer: FileContainer): Promise<void> => {
    const region = await getEngagementRegion({ engagementId });
    return uploadFile(
        region?.endpointUrl || defaultBaseUrl,
        `/api/application/engagement/${engagementId}/applicationName/${applicationName}/import`,
        fileContainer,
    );
};

export const importApplicationDataToProject = async (
    engagementId: string,
    projectId: string,
    applicationName: number,
    fileContainer: FileContainer,
): Promise<void> => {
    const region = await getProjectRegion({ engagementId, projectId });
    return uploadFile(
        region?.endpointUrl || defaultBaseUrl,
        `/api/application/engagement/${engagementId}/project/${projectId}/applicationName/${applicationName}/import`,
        fileContainer,
    );
};

const getEngagementVatSummaryDataPath = "/api/Vat/engagement/{engagementId}/vat/summary";
export type GetVatSummaryResponse = components["schemas"]["VatSummaryResponseModel"];
export type GetEngagementVatSummaryDataArgs = paths[typeof getEngagementVatSummaryDataPath]["get"]["parameters"]["path"];
export const getEngagementVatSummaryData = async (args: GetEngagementVatSummaryDataArgs): Promise<GetVatSummaryResponse[]> =>
    engagementApi(args, client => process(client.GET(getEngagementVatSummaryDataPath, { params: { path: args } })));

const getProjectVatSummaryDatPath = "/api/Vat/engagement/{engagementId}/project/{projectId}/vat/summary";
export type GetProjectVatSummaryDataArgs = paths[typeof getProjectVatSummaryDatPath]["get"]["parameters"]["path"];
export const getProjectVatSummaryData = async (args: GetProjectVatSummaryDataArgs): Promise<GetVatSummaryResponse[]> =>
    projectApi(args, client => process(client.GET(getProjectVatSummaryDatPath, { params: { path: args } })));

export const exportEngagementApplicationData = async (
    engagementId: string,
    applicationName: number,
    progressCallback: DownloadProgressCallback,
): Promise<Blob> => {
    const region = await getEngagementRegion({ engagementId });
    const response = await downloadFile(
        region?.endpointUrl || defaultBaseUrl,
        `/api/application/engagement/${engagementId}/applicationName/${applicationName}/export`,
        progressCallback,
    );
    if (response.ok) {
        return response.blob();
    } else {
        throw new Error("The Vat Data could not be exported");
    }
};

export const exportProjectApplicationData = async (
    engagementId: string,
    projectId: string,
    applicationName: number,
    progressCallback: DownloadProgressCallback,
): Promise<Blob> => {
    const region = await getEngagementRegion({ engagementId });
    const response = await downloadFile(
        region?.endpointUrl || defaultBaseUrl,
        `/api/application/engagement/${engagementId}/project/${projectId}/applicationName/${applicationName}/export`,
        progressCallback,
    );
    if (response.ok) {
        return response.blob();
    } else {
        throw new Error("The Vat Data could not be exported");
    }
};
