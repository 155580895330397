import { FunctionComponent } from "react";

import { useParams } from "react-router-dom";

import { BaseApplicationsPage } from "./BaseApplicationsPage.tsx";

export const EngagementApplicationsPage: FunctionComponent = () => {
    const { engagementId } = useParams();
    return <BaseApplicationsPage engagementId={engagementId || ""} />;
};
