import { FC, PropsWithChildren, ReactNode, SVGProps } from "react";

import { Box, Flex, FlexProps, Grid, GridItem, Icon, Text } from "@chakra-ui/react";
import { Footer } from "am-tax-fe-core";

import AMTaxLogoUsWhite from "../../assets/images/am_tax_logo_usanduk_white.svg?react";

export type Props = {
    appName: string;
    logo?: ReactNode;
    footer?: ReactNode;
    logoSvg?: FC<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
} & Pick<FlexProps, "bgImage">;

/**
 * The login page and the login form are separate components. This allows for alternate login implementations.
 * @param appName
 * @param bgImage
 * @param logo
 * @param logoSvg
 * @param footer
 * @param children
 * @constructor
 */
export const LocalLoginPage: FC<PropsWithChildren<Props>> = ({ bgImage, logo, logoSvg, footer, children }) => {
    return (
        <Box bgImage={bgImage} bgSize={"cover"} minHeight="100vh" w={"100%"}>
            <Grid
                templateAreas={{
                    base: `"splash"
                         "loginForm"`,
                    lg: `"splash loginForm"`,
                }}
                templateColumns={{ base: "auto", lg: "1fr minmax(auto, 1fr)" }}
                templateRows={{ base: "1fr auto auto auto", lg: "1fr auto" }}
                w="100%"
                h="100%"
                minHeight="90vh"
            >
                <GridItem gridArea={"splash"}>
                    <Flex
                        direction={"column"}
                        backgroundImage="linear(90deg,rgba(0,0,0,0.5),transparent)"
                        align={"center"}
                        justifyContent={"center"}
                        h={"100%"}
                    >
                        <Box>{logo ? logo : <Icon as={logoSvg || AMTaxLogoUsWhite} boxSize={{ base: "6rem", sm: "9rem", xl: "12rem", "2xl": "15rem" }} />}</Box>
                    </Flex>
                </GridItem>

                <GridItem gridArea={"loginForm"} bg="white">
                    <Flex
                        align="center"
                        justify="center"
                        direction="column"
                        height="100%"
                        px={{ base: "3rem", lg: "5rem" }}
                        py={{ base: "5rem", lg: "3rem" }}
                        gap={"1rem"}
                    >
                        {children}
                        <Box width="100%" py="1rem">
                            <Text>Use of Engage is subject to your client engagement with Alvarez and Marsal.</Text>
                        </Box>
                    </Flex>
                </GridItem>
            </Grid>
            {footer ? footer : <Footer />}
        </Box>
    );
};
