import { FunctionComponent } from "react";

import { Button, Card } from "@chakra-ui/react";
import { JSONSchemaType } from "ajv";
import { DynamicForm, ExpandedJSONSchema7, PageLoading } from "am-tax-fe-core";

import { CreateDynamicFormArgs, DynamicFormResponse, UpdateDynamicFormArgs } from "../../api/questionnaires/api.ts";
import { Mutation } from "../../utils/query-utils.ts";
import { Page } from "../Page.tsx";

export interface QuestionnaireProps {
    engagementId: string;
    projectId?: string | undefined;
    dynamicFormDefinitionId: string;
    goBack: () => void;
    isLoadingFormDefintions: boolean;
    schema?: string | null | undefined;
    isLoadingFormData: boolean;
    dynamicFormResponse?: DynamicFormResponse | undefined;
    createFormDataMutation: Mutation<CreateDynamicFormArgs, DynamicFormResponse>;
    updateFormDataMutation: Mutation<UpdateDynamicFormArgs, DynamicFormResponse>;
}

type SchemaData = JSONSchemaType<ExpandedJSONSchema7>;

export const BaseQuestionnairePage: FunctionComponent<QuestionnaireProps> = ({
    engagementId,
    projectId,
    dynamicFormDefinitionId,
    isLoadingFormDefintions,
    schema,
    isLoadingFormData,
    dynamicFormResponse,
    createFormDataMutation,
    updateFormDataMutation,
    goBack,
}: QuestionnaireProps) => {
    const formSchema = schema ? (JSON.parse(schema) as JSONSchemaType<ExpandedJSONSchema7>) : undefined;
    const formData = dynamicFormResponse?.data;
    const defaultValues = formData ? (JSON.parse(formData) as JSONSchemaType<SchemaData>) : undefined;

    const onSubmit = async (fields: JSONSchemaType<SchemaData>) => {
        if (dynamicFormResponse?.id) {
            const args = updateFormDataMutation.updateArgs({ engagementId, projectId, dynamicFormId: dynamicFormResponse?.id, data: JSON.stringify(fields) });
            await updateFormDataMutation.query.mutateAsync(args);
        } else {
            const args = createFormDataMutation.updateArgs({ engagementId, projectId, dynamicFormDefinitionId, data: JSON.stringify(fields) });
            await createFormDataMutation.query.mutateAsync(args);
        }
    };

    const isLoading = isLoadingFormDefintions || isLoadingFormData;
    const isUpdating = updateFormDataMutation.query.isPending || createFormDataMutation.query.isPending;

    return (
        <Page
            title="Questionnaire"
            gutter={
                <Button variant="outline" onClick={goBack}>
                    Back to Questionnaires
                </Button>
            }
        >
            {isLoading && <PageLoading text={"Loading..."} />}

            {formSchema && !isLoading && (
                <Card p="1rem" maxWidth="1440px">
                    <DynamicForm formSchema={formSchema} defaultValues={defaultValues} onSubmit={onSubmit} submitting={isUpdating} />
                </Card>
            )}
        </Page>
    );
};
