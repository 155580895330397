import { FunctionComponent, ReactNode } from "react";

import { Box, Text } from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";

import { AppPermissions, Permission, SecurityArea } from "../../auth";
import { UnauthorizedPage } from "../../pages/UnauthorizedPage";

export interface RouteGuardProps {
    appPermissions: AppPermissions;
    securityArea?: SecurityArea;
    hasAny?: Permission[];
    render: ReactNode;
}

const LoadingContent: FunctionComponent = () => {
    return (
        <Box textAlign="center" py={10} px={6}>
            <BeatLoader color={"var(--chakra-colors-blue-700)"} size={10} />
            <Text fontSize="lg" color="blue.400">
                Route Guard...
            </Text>
        </Box>
    );
};

export const RouteGuard: FunctionComponent<RouteGuardProps> = ({ appPermissions, securityArea, hasAny, render }) => {
    if (import.meta.env.VITE_BYPASS_AUTH === "true" || import.meta.env.VITE_MOCK_ENTIRE_BACKEND === "true") {
        return <>{render}</>;
    }

    if (appPermissions.loading) {
        return <LoadingContent />;
    } else if (hasAny && appPermissions.hasAny(...hasAny)) {
        return render;
    } else if (securityArea && appPermissions.showArea(securityArea)) {
        return render;
    } else {
        return <UnauthorizedPage />;
    }
};
