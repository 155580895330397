import { FunctionComponent, ReactNode } from "react";

import { Permission, SecurityArea } from "../../auth";
import { useAppPermissions } from "../../hooks";
import { RouteGuard } from "../EngagementRouteGuard/RouteGuard";

export interface RouteGuardProps {
    securityArea?: SecurityArea;
    hasAny?: Permission[];
    render: ReactNode;
}

export const AppRouteGuard: FunctionComponent<RouteGuardProps> = ({ securityArea, hasAny, render }) => {
    const appPermissions = useAppPermissions();

    return <RouteGuard appPermissions={appPermissions} securityArea={securityArea} hasAny={hasAny} render={render} />;
};
