import { applyParams, downloadFile, DownloadProgressCallback, process } from "am-tax-fe-core";

import { engagementApi, getEngagementRegion, getProjectRegion, projectApi } from "../api";
import { components } from "../generated/generated-api";

const ENG_FORM_DEF_PATH = "/api/engagement/{engagementId}/dynamicformdefinitions";
const PRJ_FORM_DEF_PATH = "/api/engagement/{engagementId}/project/{projectId}/dynamicformdefinitions";
export type DynamicFormDefinitionResponse = components["schemas"]["DynamicFormDefinitionResponse"];

const ENG_FORM_DATA_PATH = "/api/engagement/{engagementId}/dynamicFormDefinition/{dynamicFormDefinitionId}/dynamicforms";
const PRJ_FORM_DATA_PATH = "/api/engagement/{engagementId}/project/{projectId}/dynamicFormDefinition/{dynamicFormDefinitionId}/dynamicforms";
export type DynamicFormResponse = components["schemas"]["DynamicFormResponse"];

const ENG_FORM_DATA_CREATE_PATH = "/api/engagement/{engagementId}/dynamicFormDefinition/{dynamicFormDefinitionId}/dynamicform";
const PRJ_FORM_DATA_CREATE_PATH = "/api/engagement/{engagementId}/project/{projectId}/dynamicFormDefinition/{dynamicFormDefinitionId}/dynamicform";

const ENG_FORM_DATA_UPDATE_PATH = "/api/engagement/{engagementId}/dynamicForm/{dynamicFormId}/dynamicform";
const PRJ_FORM_DATA_UPDATE_PATH = "/api/engagement/{engagementId}/project/{projectId}/dynamicForm/{dynamicFormId}/dynamicform";

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const ENG_FORM_DATA_DOWNLOAD_PATH = "/api/engagement/{engagementId}/dynamicforms/content";
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const PRJ_FORM_DATA_DOWNLOAD_PATH = "/api/engagement/{engagementId}/project/{projectId}/dynamicforms/content";

const ENG_FORM_DATA_DOWNLOAD_POST_PATH = "/api/engagement/{engagementId}/dynamicformsbyid/content";
const PRJ_FORM_DATA_DOWNLOAD_POST_PATH = "/api/engagement/{engagementId}/project/{projectId}/dynamicformsbyid/content";

export const getEngagementFormDefinitions = async (engagementId: string): Promise<DynamicFormDefinitionResponse[]> =>
    engagementApi({ engagementId }, client => process(client.GET(ENG_FORM_DEF_PATH, { params: { path: { engagementId } } })));

export const getProjectFormDefinitions = async (engagementId: string, projectId: string): Promise<DynamicFormDefinitionResponse[]> =>
    projectApi({ engagementId, projectId }, client => process(client.GET(PRJ_FORM_DEF_PATH, { params: { path: { engagementId, projectId } } })));

export const getEngagementFormData = async (engagementId: string, dynamicFormDefinitionId: string): Promise<DynamicFormResponse> =>
    engagementApi({ engagementId }, client => process(client.GET(ENG_FORM_DATA_PATH, { params: { path: { engagementId, dynamicFormDefinitionId } } })));

export const getProjectFormData = async (engagementId: string, projectId: string, dynamicFormDefinitionId: string): Promise<DynamicFormResponse> =>
    projectApi({ engagementId, projectId }, client =>
        process(client.GET(PRJ_FORM_DATA_PATH, { params: { path: { engagementId, projectId, dynamicFormDefinitionId } } })),
    );

export type CreateDynamicFormArgs = { engagementId: string; projectId?: string; dynamicFormDefinitionId: string; data: string };
export type UpdateDynamicFormArgs = { engagementId: string; projectId?: string; dynamicFormId: string; data: string };

export const createEngagementDynamicForm = async ({ engagementId, dynamicFormDefinitionId, data }: CreateDynamicFormArgs): Promise<DynamicFormResponse> =>
    engagementApi({ engagementId }, client =>
        process(
            client.POST(ENG_FORM_DATA_CREATE_PATH, {
                params: { path: { engagementId, dynamicFormDefinitionId } },
                body: data,
            }),
        ),
    );

export const createProjectDynamicForm = async ({
    engagementId,
    projectId,
    dynamicFormDefinitionId,
    data,
}: CreateDynamicFormArgs): Promise<DynamicFormResponse> => {
    return projectApi({ engagementId, projectId: projectId || "" }, client =>
        process(
            client.POST(PRJ_FORM_DATA_CREATE_PATH, {
                params: { path: { engagementId, projectId: projectId || "", dynamicFormDefinitionId } },
                body: data,
            }),
        ),
    );
};

export const updateEngagementDynamicForm = async ({ engagementId, dynamicFormId, data }: UpdateDynamicFormArgs): Promise<DynamicFormResponse> => {
    return engagementApi({ engagementId }, client =>
        process(
            client.PUT(ENG_FORM_DATA_UPDATE_PATH, {
                params: { path: { engagementId, dynamicFormId } },
                body: data,
            }),
        ),
    );
};

export const updateProjectDynamicForm = async ({ engagementId, projectId, dynamicFormId, data }: UpdateDynamicFormArgs): Promise<DynamicFormResponse> =>
    projectApi({ engagementId, projectId: projectId || "" }, client =>
        process(
            client.PUT(PRJ_FORM_DATA_UPDATE_PATH, {
                params: { path: { engagementId, projectId: projectId || "", dynamicFormId } },
                body: data,
            }),
        ),
    );

export type DownloadEngagementFormDefinitionsArgs = { engagementId: string; definitionIds: string[] } & { progressCallback: DownloadProgressCallback };
export const downloadEngagementDynamicForms = async (args: DownloadEngagementFormDefinitionsArgs): Promise<Blob> => {
    const region = await getEngagementRegion({ engagementId: args.engagementId });
    const response = await downloadFile(
        region?.endpointUrl || "",
        applyParams(ENG_FORM_DATA_DOWNLOAD_POST_PATH, { engagementId: args.engagementId }),
        args.progressCallback,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ formDefinitionIds: args.definitionIds }),
        },
    );
    if (response.ok) {
        return response.blob();
    } else {
        throw new Error("The file could not be downloaded.");
    }
};

export type DownloadProjectFormDefinitionsArgs = { engagementId: string; projectId: string; definitionIds: string[] } & {
    progressCallback: DownloadProgressCallback;
};
export const downloadProjectDynamicForms = async (args: DownloadProjectFormDefinitionsArgs): Promise<Blob> => {
    const region = await getProjectRegion({ engagementId: args.engagementId, projectId: args.projectId });
    const response = await downloadFile(
        region?.endpointUrl || "",
        applyParams(PRJ_FORM_DATA_DOWNLOAD_POST_PATH, { engagementId: args.engagementId, projectId: args.projectId }),
        args.progressCallback,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ formDefinitionIds: args.definitionIds }),
        },
    );
    if (response.ok) {
        return response.blob();
    } else {
        throw new Error("The file could not be downloaded.");
    }
};
