import { FunctionComponent } from "react";

import { Card, CardBody } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { Page } from "../Page.tsx";

import { EventForm } from "./EventForm.tsx";
import { useEventFormForEngagement } from "./useEventFormForEngagement.ts";

export const EngagementEventPage: FunctionComponent = () => {
    const { engagementId, eventId } = useParams();
    const eventFormProps = useEventFormForEngagement(engagementId ?? "", eventId ?? "");

    return (
        <Page title={`${eventFormProps.eventQuery.data?.eventId ? "Edit" : "Add"} Event`}>
            <Card>
                <CardBody>
                    <EventForm {...eventFormProps} />
                </CardBody>
            </Card>
        </Page>
    );
};
