import { FunctionComponent } from "react";

import { Card, CardBody } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { EventForm } from "../EngagementEventPage/EventForm.tsx";
import { Page } from "../Page.tsx";

import { useEventFormForProject } from "./useEventFormForProject.ts";

export const ProjectEventPage: FunctionComponent = () => {
    const { engagementId, projectId, eventId } = useParams();
    const eventFormProps = useEventFormForProject(engagementId ?? "", projectId ?? "", eventId ?? "");

    return (
        <Page title={`${eventFormProps.eventQuery.data?.eventId ? "Edit" : "Add"} Event`}>
            <Card>
                <CardBody>
                    <EventForm {...eventFormProps} />
                </CardBody>
            </Card>
        </Page>
    );
};
