import { useMemo } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import {
    useSaveProjectTaxNotice,
    useProjectTaxNotice,
    useUploadDocumentToProjectTaxNotice,
    useProject,
    useDeleteProjectTaxNotice,
    PostProjectTaxNoticeArgs,
    PutProjectTaxNoticeArgs,
    DeleteProjectTaxNoticeArgs,
    UploadDocumentsToProjectTaxNoticeArgs,
    TaxNotice,
    invalidateProjectTaxNoticeQueries,
    invalidateDocumentCacheForProjectAndFolder,
} from "../../api";
import { Mutation } from "../../utils/query-utils";
import {
    DeleteTaxNoticeArgs,
    ProjectEngagement,
    SaveTaxNoticeArgs,
    TaxNoticeFormProps,
    UploadDocumentsToTaxNoticeArgs,
} from "../EngagementTaxNoticePage/TaxNoticeForm";

export function useTaxNoticeFormForProject(engagementId: string, projectId: string, taxNoticeId: string): TaxNoticeFormProps {
    const navigate = useNavigate();
    const projectEngagementQuery = useProject(engagementId, projectId);
    const saveTaxNoticeQuery = useSaveProjectTaxNotice();
    const deleteTaxNoticeQuery = useDeleteProjectTaxNotice();

    const taxNoticeQuery = useProjectTaxNotice(engagementId, projectId, taxNoticeId);
    const uploadFileToTaxNoticeQuery = useUploadDocumentToProjectTaxNotice();

    const saveTaxNoticeMutation = useMemo(() => {
        class SaveMutation extends Mutation<PostProjectTaxNoticeArgs | PutProjectTaxNoticeArgs> {
            updateArgs(args: PostProjectTaxNoticeArgs | PutProjectTaxNoticeArgs) {
                return {
                    ...args,
                    projectId,
                };
            }
        }
        return new SaveMutation(saveTaxNoticeQuery);
    }, [projectId, saveTaxNoticeQuery]);

    const deleteTaxNoticeMutation = useMemo(() => {
        class DeleteMutation extends Mutation<DeleteProjectTaxNoticeArgs> {
            updateArgs(args: DeleteProjectTaxNoticeArgs) {
                return {
                    ...args,
                    projectId,
                };
            }
        }
        return new DeleteMutation(deleteTaxNoticeQuery) as Mutation<DeleteProjectTaxNoticeArgs>;
    }, [projectId, deleteTaxNoticeQuery]);

    const uploadFileToTaxNoticeMutation = useMemo(() => {
        class UploadMutation extends Mutation<UploadDocumentsToProjectTaxNoticeArgs> {
            updateArgs(args: UploadDocumentsToProjectTaxNoticeArgs) {
                return {
                    ...args,
                    projectId,
                };
            }
        }
        return new UploadMutation(uploadFileToTaxNoticeQuery) as Mutation<UploadDocumentsToProjectTaxNoticeArgs>;
    }, [projectId, uploadFileToTaxNoticeQuery]);

    const queryClient = useQueryClient();
    const onSaveComplete = (projectOrEngagement: ProjectEngagement, taxNotice: TaxNotice, filesUploaded?: boolean) => {
        const currFolderId = projectOrEngagement.clientDocumentsRootFolderId;
        invalidateProjectTaxNoticeQueries(queryClient, taxNotice.engagementId || "", taxNotice.projectId || "", taxNotice.id || "");
        if (filesUploaded) {
            invalidateDocumentCacheForProjectAndFolder(queryClient, taxNotice.engagementId || "", taxNotice.projectId || "", currFolderId);
        }
    };

    const navigateBack = () => {
        navigate(`/engagement/${engagementId}/project/${projectId}/taxnotices/`, { relative: "path" });
    };

    return {
        projectEngagementQuery,
        taxNoticeQuery,
        saveTaxNoticeMutation: saveTaxNoticeMutation as Mutation<SaveTaxNoticeArgs, TaxNotice>,
        deleteTaxNoticeMutation: deleteTaxNoticeMutation as Mutation<DeleteTaxNoticeArgs, number>,
        uploadFileToTaxNoticeMutation: uploadFileToTaxNoticeMutation as Mutation<UploadDocumentsToTaxNoticeArgs>,
        navigateBack,
        onSaveComplete,
    };
}
