import { FunctionComponent } from "react";

import { Flex, Box, Button, Card, CardHeader, CardBody, Badge } from "@chakra-ui/react";
import { IconPlus, IconFileCheck } from "@tabler/icons-react";
import { UseQueryResult } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "am-tax-fe-core";
import { useNavigate } from "react-router-dom";

import { TaxNotice } from "../../api";
import { Page } from "../Page.tsx";

export type TaxNoticesPageProps = {
    taxNoticesQuery: UseQueryResult<TaxNotice[]>;
};

export const TaxNoticesPage: FunctionComponent<TaxNoticesPageProps> = ({ taxNoticesQuery }) => {
    const navigate = useNavigate();

    const taxNoticesData = taxNoticesQuery.data || [];

    function getBadgeColorForTaxNoticeStatus(status: string) {
        if (status == "In Progress") return "blue";
        if (status == "In Review") return "orange";
        return "green";
    }

    function goToTaxNotice(taxNoticeId: string) {
        navigate(`../taxnotices/taxnotice/${taxNoticeId}`, { relative: "path" });
    }

    const columnHelper = createColumnHelper<TaxNotice>();

    // TODO: Add documents to tax-notice model
    const taxNoticeDocuments = [];

    return (
        <Page title="Tax Notices">
            <Flex justify="flex-end" mb={"1rem"}>
                <Button onClick={() => navigate("../taxnotices/taxnotice/", { relative: "path" })} leftIcon={<IconPlus />}>
                    Add Tax Notice
                </Button>
            </Flex>
            <Card>
                <CardHeader bgGradient="linear(to-br, purple.500, purple.300)"></CardHeader>
                <CardBody overflowX={"auto"}>
                    <DataTable
                        tableProps={{ size: "sm" }}
                        data={taxNoticesData}
                        isLoading={taxNoticesQuery.isLoading}
                        noData={"No Tax Notices Found."}
                        onRowClicked={taxNotice => {
                            goToTaxNotice(taxNotice.id ?? "");
                        }}
                        columns={[
                            columnHelper.accessor("noticeId", {
                                header: "Notice",
                                cell: props => {
                                    const taxNotice = props.row.original;
                                    return (
                                        <Flex alignItems={"center"} justifyContent={"space-between"} gap=".25rem">
                                            <Box>{taxNotice.noticeId}</Box>
                                            <Box>{taxNoticeDocuments.length > 0 && <IconFileCheck />}</Box>
                                        </Flex>
                                    );
                                },
                            }),
                            columnHelper.accessor("taxPayerId", {
                                header: "Tax Payer ID",
                                cell: props => {
                                    const taxNotice = props.row.original;
                                    return taxNotice.taxPayerId;
                                },
                            }),
                            columnHelper.accessor("taxYear", {
                                header: "Tax Year",
                                cell: props => {
                                    const taxNotice = props.row.original;
                                    return taxNotice.taxYear;
                                },
                            }),
                            columnHelper.accessor("jurisdiction", {
                                header: "Jurisdiction",
                                cell: props => {
                                    const taxNotice = props.row.original;
                                    return taxNotice.jurisdiction;
                                },
                            }),
                            columnHelper.accessor("notes", {
                                header: "Client Notes",
                                cell: props => {
                                    const taxNotice = props.row.original;
                                    return taxNotice.notes;
                                },
                            }),
                            columnHelper.accessor("status", {
                                header: "Status",
                                cell: props => {
                                    const taxNotice = props.row.original;
                                    return <Badge colorScheme={getBadgeColorForTaxNoticeStatus(taxNotice.status ?? "")}>{taxNotice.status}</Badge>;
                                },
                            }),
                        ]}
                    />
                </CardBody>
            </Card>
        </Page>
    );
};
