import { FunctionComponent } from "react";

import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Flex } from "@chakra-ui/react";
import { PageLoading } from "am-tax-fe-core";

import { Page } from "../Page";

interface Props {
    hasPermission: boolean;
    onClick: () => void;
    isLoading: boolean;
    isInternaluser: boolean;
}

const EnableReportsPage: FunctionComponent<Props> = ({ hasPermission, onClick, isLoading, isInternaluser }) => {
    if (isLoading) {
        return <PageLoading text={"Page loading..."} />;
    }

    if (!hasPermission || !isInternaluser) {
        return (
            <Page title="Reports">
                <Alert status="info" variant="subtle" flexDirection="column">
                    <Flex ml="1rem" alignItems="center" width="100%">
                        <AlertIcon boxSize="40px" mr="1rem" />
                        <Box>
                            <AlertTitle mt={4} mb={1} fontSize="lg">
                                Start using Reports
                            </AlertTitle>

                            <AlertDescription textAlign="left">
                                The Reports feature is not enabled. Please contact Engagement/Project Administrator to enable reports.
                            </AlertDescription>
                        </Box>
                    </Flex>
                </Alert>
            </Page>
        );
    }

    return (
        <Page title="Reports">
            <Flex gap={"1rem"} mb={"2rem"} bg={"gray.50"} p={"1rem"} align={"left"}>
                <Alert status="info" variant="subtle">
                    <Box>
                        <Flex ml="1rem" align={"left"} width="100%">
                            <AlertIcon boxSize="40px" mr="1rem" />
                            <Box>
                                <AlertTitle mt={4} mb={1} fontSize="lg">
                                    Start using Reports
                                </AlertTitle>
                                <AlertDescription textAlign="left">
                                    You need to enable the report feature before you can start uploading any reports. To do this, simply click the "Enable
                                    Reports" button to activate the reporting functionality.
                                </AlertDescription>
                            </Box>
                        </Flex>

                        <Flex ml={"3rem"} width="100%">
                            <Button colorScheme="blue" size="lg" m={"1rem"} onClick={onClick} isLoading={isLoading} loadingText="Enabling Reports..">
                                Enable Reports
                            </Button>
                        </Flex>
                    </Box>
                </Alert>
            </Flex>
        </Page>
    );
};

export default EnableReportsPage;
