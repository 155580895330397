import { useMemo } from "react";

import { useNavigate } from "react-router-dom";

import {
    useCreateEngagementFormData,
    useEngagementFormData,
    useEngagementFormDefinitions,
    useUpdateEngagementFormData,
} from "../../api/questionnaires/api-queries";
import { Mutation } from "../../utils/query-utils.ts";

import { QuestionnaireProps } from "./BaseQuestionnairePage";

export function useEngagementQuestionnaire(engagementId: string, dynamicFormDefinitionId: string): QuestionnaireProps {
    const { data: engagementSchemas, isLoading: isLoadingFormDefintions } = useEngagementFormDefinitions(engagementId, true);
    const schema = engagementSchemas?.find(x => x.id === dynamicFormDefinitionId)?.schema;

    const { data: engSchemaData, isLoading: isLoadingFormData } = useEngagementFormData(engagementId, dynamicFormDefinitionId, true);

    const createEngagementFormData = useCreateEngagementFormData();
    const createFormDataMutation = useMemo(() => new Mutation(createEngagementFormData), [createEngagementFormData]);

    const updateEngagementFormData = useUpdateEngagementFormData();
    const updateFormDataMutation = useMemo(() => new Mutation(updateEngagementFormData), [updateEngagementFormData]);

    const navigate = useNavigate();

    const goBack = () => {
        navigate(`/engagement/${engagementId}/questionnaires/`, { relative: "path" });
    };

    return {
        engagementId,
        dynamicFormDefinitionId,
        isLoadingFormDefintions,
        schema: schema,
        isLoadingFormData,
        dynamicFormResponse: engSchemaData,
        createFormDataMutation,
        updateFormDataMutation,
        goBack,
    };
}
