import { FunctionComponent } from "react";

import { Box, Button, Card, CardBody, CardHeader } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { TaxNoticeForm } from "../EngagementTaxNoticePage/TaxNoticeForm.tsx";
import { Page } from "../Page.tsx";

import { useTaxNoticeFormForEngagement } from "./useTaxNoticeFormForEngagement.ts";

//import "../../../../AM.Tax.ClientPortal.Data.Entities/Definitions/ColumnDefinitionConstants.cs";

export const EngagementTaxNoticePage: FunctionComponent = () => {
    const { engagementId, taxNoticeId } = useParams();

    const taxNoticeFormProps = useTaxNoticeFormForEngagement(engagementId ?? "", taxNoticeId ?? "");

    const gutterMenu = (
        <Box>
            <Button variant="outline" onClick={taxNoticeFormProps.navigateBack}>
                Back to Notices
            </Button>
        </Box>
    );

    return (
        <Page title={`Add Tax Notice`} gutter={gutterMenu}>
            <Card>
                <CardHeader bgGradient="linear(to-br, purple.500, purple.300)"></CardHeader>
                <CardBody>
                    <TaxNoticeForm {...taxNoticeFormProps} />
                </CardBody>
            </Card>
        </Page>
    );
};
