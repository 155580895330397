import { FunctionComponent, RefObject, useRef, useEffect, useState } from "react";

import {
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Flex,
    Box,
    Button,
    Switch,
} from "@chakra-ui/react";
import { FileContainer, Textarea } from "am-tax-fe-core";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

import { Report, useCurrentUser } from "../../api";
import { User } from "../../api";
import {
    useEngagementCreateReport,
    useEngagementUpdateReport,
    useProjectCreateReport,
    useProjectUpdateReport,
    useRemoveReport,
    useUploadEngagementReportFile,
    useUploadProjectReportFile,
} from "../../api/apiQueries";
import UploadButton from "../../components/UploadButton/UploadButton";

interface Props {
    engagementId: string;
    projectId?: string;
    report?: Report;
    focusAfterClosingRef?: RefObject<HTMLElement>;
    show: boolean;
    closeDialog: () => void;
}

type Inputs = {
    title: string;
    description: string;
    fileName: string;
    isPublished: boolean;
};

const EditReportDialog: FunctionComponent<Props> = ({ engagementId, projectId, report, show, closeDialog }: Props) => {
    const initialTitleRef = useRef<HTMLInputElement | null>(null);
    const currentUserQuery = useCurrentUser();
    const currentUser = currentUserQuery.data as User;

    const [selectedFileContainer, setSelectedFileContainer] = useState<FileContainer | undefined>();

    const { mutateAsync: createEngagementReportAsync, isPending: createEngagementReportPending } = useEngagementCreateReport();
    const { mutateAsync: createProjectReportAsync, isPending: createProjecttReportPending } = useProjectCreateReport();
    const createReportAsync = projectId ? createProjectReportAsync : createEngagementReportAsync;
    const createReportPending = projectId ? createProjecttReportPending : createEngagementReportPending;

    const { mutateAsync: updateProjectReportAsync, isPending: updateProjectReportPending } = useProjectUpdateReport();
    const { mutateAsync: updateEngagementReportAsync, isPending: updateEngagementReportPending } = useEngagementUpdateReport();
    const { mutateAsync: removeReportAsync, isPending: removeReportPending } = useRemoveReport();
    const { mutateAsync: uploadEngagementReportFileAsync, isPending: uploadEngagementReportFilePending } = useUploadEngagementReportFile();
    const { mutateAsync: uploadProjectReportFileAsync, isPending: uploadProjectReportFilePending } = useUploadProjectReportFile();
    const uploadReportFileAsync = projectId ? uploadProjectReportFileAsync : uploadEngagementReportFileAsync;
    const uploadReportFilePending = projectId ? uploadProjectReportFilePending : uploadEngagementReportFilePending;
    const updateReportFilePending = projectId ? updateProjectReportPending : updateEngagementReportPending;

    const {
        register,
        handleSubmit,
        formState: { errors },
        resetField,
        setValue,
        control,
    } = useForm<Inputs>();

    useEffect(() => {
        if (show) {
            if (report) {
                setValue("title", report.title);
                setValue("description", report.description || "");
                setValue("fileName", report.url);
                setValue("isPublished", report.isPublishedToClient);
                //                setValue("showOnDashboard", report.showOnDashboard);
            } else {
                setValue("isPublished", true);
            }
        }
    }, [show, setValue, report]);

    const handleClose = () => {
        closeDialog();
        resetField("title");
        resetField("description");
        resetField("fileName");
        resetField("isPublished");
        //        resetField("showOnDashboard");
        setSelectedFileContainer(undefined);
    };

    const handleRemoveReportClick = (rep: Report) => {
        removeReportAsync(
            { engagementId, ...rep },
            {
                onSuccess: () => {
                    handleClose();
                },
            },
        );
    };

    const updateReport = (data: Inputs, report: Report) => {
        const engagementArgs = {
            engagementId,
            ...report,
            title: data.title,
            description: data.description,
            url: data.fileName,
            isPublishedToClient: data.isPublished,
        };
        if (!projectId) {
            updateEngagementReportAsync(engagementArgs, {
                onSuccess: () => {
                    handleClose();
                },
            });
        } else {
            updateProjectReportAsync(
                { ...engagementArgs, projectId },
                {
                    onSuccess: () => {
                        handleClose();
                    },
                },
            );
        }
    };

    const createReport = async (data: Inputs) => {
        if (!selectedFileContainer) {
            return;
        }
        const emptyReport = {} as Report;
        const isPublished = currentUser.isInternal ? data.isPublished : true;
        const args = {
            ...emptyReport,
            engagementId,
            projectId: projectId as string,
            title: data.title,
            description: data.description,
            url: data.fileName,
            isPublishedToClient: isPublished,
            //            showOnDashboard: data.showOnDashboard,
        };
        const report = await createReportAsync(args);
        if (!report || !report.id) {
            return; // failed
        }

        const uploadArgs = { engagementId, projectId: projectId || "", reportId: report.id, fileContainer: selectedFileContainer };

        // upload here
        await uploadReportFileAsync(uploadArgs);

        handleClose();
    };

    const onSubmit: SubmitHandler<Inputs> = data => {
        if (report) {
            updateReport(data, report);
        } else {
            createReport(data);
        }
    };

    // dev-note: deconstructing register to get hold of internal ref
    const { ref: titleRef, ...titleRest } = register("title", {
        required: "Please fill in a report title",
    });

    const onFileSelectionChanged = (files: FileContainer[] | FileContainer | undefined) => {
        const fileContainer = files as FileContainer | undefined;
        setSelectedFileContainer(fileContainer);
        setValue("fileName", fileContainer ? fileContainer.file.name : "", { shouldValidate: !!fileContainer });
    };

    const getFormattedFileSize = (bitSize: number) => {
        if (!bitSize) {
            return "N/A";
        }
        const kbSize = bitSize / 1024;
        if (kbSize < 1024) {
            return `${kbSize.toFixed(0)} KB`;
        }
        const mbSize = kbSize / 1024;
        return `${mbSize.toFixed(1)} MB`;
    };

    return (
        <Modal initialFocusRef={initialTitleRef} finalFocusRef={initialTitleRef} isOpen={show} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader>{report ? "Edit " : "Add "} Report</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex gap="1.5rem" direction="column">
                            <FormControl isInvalid={errors.title ? true : undefined}>
                                <FormLabel>Title</FormLabel>
                                <Input
                                    placeholder="Fill in title here.."
                                    {...titleRest}
                                    ref={e => {
                                        titleRef(e);
                                        initialTitleRef.current = e;
                                    }}
                                />
                                <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors?.description}>
                                <Flex>
                                    <FormLabel>Description</FormLabel>
                                </Flex>
                                <Textarea placeholder="Fill in report description here.." {...register("description")} maxLength={500} />
                                <FormErrorMessage>{errors?.description?.message}</FormErrorMessage>
                            </FormControl>
                            {!report && (
                                <FormControl isInvalid={errors.fileName ? true : undefined}>
                                    <Controller
                                        control={control}
                                        name="fileName"
                                        rules={{
                                            required: "Please select a report file (.pbix)",
                                        }}
                                        render={() => (
                                            <>
                                                <UploadButton title="Select Report File" onFileSelectionChanged={onFileSelectionChanged} accept=".pbix" />
                                                {selectedFileContainer && (
                                                    <Flex direction="column" gap=".25rem" my=".5rem">
                                                        <Box>Name: {selectedFileContainer.file.name}</Box>
                                                        <Box>Size: {getFormattedFileSize(selectedFileContainer.file.size)}</Box>
                                                    </Flex>
                                                )}
                                            </>
                                        )}
                                    ></Controller>
                                    <FormErrorMessage>{errors?.fileName?.message}</FormErrorMessage>
                                </FormControl>
                            )}
                            {/*{enableToggleDashboard && (*/}
                            {/*    <FormControl isInvalid={!!errors?.showOnDashboard} display="flex" justifyContent="flex-end" alignItems="center" gap="1rem">*/}
                            {/*        <FormLabel m={0}>Show in dashboard</FormLabel>*/}
                            {/*        <Switch {...register("showOnDashboard")} size={"lg"} colorScheme={"green"} />*/}
                            {/*        <FormErrorMessage>{errors?.showOnDashboard?.message}</FormErrorMessage>*/}
                            {/*    </FormControl>*/}
                            {/*)}*/}
                            {currentUser.isInternal && (
                                <FormControl isInvalid={!!errors?.isPublished} display="flex" justifyContent="flex-end" alignItems="center" gap="1rem">
                                    <FormLabel m={0}>Visible to Client</FormLabel>
                                    <Switch {...register("isPublished")} size={"lg"} colorScheme={"green"} />
                                    <FormErrorMessage>{errors?.isPublished?.message}</FormErrorMessage>
                                </FormControl>
                            )}
                        </Flex>
                    </ModalBody>

                    <ModalFooter mt="2rem" px="1rem">
                        <Flex width="100%" justify={report ? "space-between" : "flex-end"} align="center" gap="2rem">
                            {report && (
                                <Button
                                    type="button"
                                    colorScheme="red"
                                    variant="ghost"
                                    fontWeight="normal"
                                    mr=".5rem"
                                    isLoading={removeReportPending}
                                    onClick={() => handleRemoveReportClick(report)}
                                >
                                    Remove
                                </Button>
                            )}
                            <Box>
                                <Button
                                    type="submit"
                                    colorScheme="blue"
                                    mr=".5rem"
                                    isLoading={createReportPending || updateReportFilePending || uploadReportFilePending}
                                >
                                    Save
                                </Button>
                                <Button type="button" variant="ghost" onClick={handleClose}>
                                    Cancel
                                </Button>
                            </Box>
                        </Flex>
                    </ModalFooter>
                </form>
            </ModalContent>
        </Modal>
    );
};

export default EditReportDialog;
