import { FunctionComponent, useMemo } from "react";

import { Accordion, Alert, AlertIcon, Box, Text } from "@chakra-ui/react";
import { WidgetLoading } from "am-tax-fe-core";

import { ConversationResponse } from "../../api";
import { useRequestConversations } from "../../api/apiQueries";

import ConversationItem from "./ConversationItem";

interface Props {
    engagementId: string;
    projectId?: string;
    requestId: string;
}

const CoversationList: FunctionComponent<Props> = (props: Props) => {
    const { engagementId, projectId, requestId } = props;
    const { data, isPending, isError, error } = useRequestConversations(requestId, engagementId, projectId);

    const sortByCreatedDate = (c1: ConversationResponse, c2: ConversationResponse) => {
        if (!c1.dateCreated || !c2.dateCreated) return 0;
        return new Date(c1.dateCreated).getTime() > new Date(c2.dateCreated).getTime() ? -1 : 1;
    };

    const sortedConversations = useMemo(() => {
        return data ? data.sort(sortByCreatedDate) : [];
    }, [data]);

    if (isPending) {
        return <WidgetLoading />;
    }

    if (isError) {
        return (
            <Alert my=".5rem" status="error" variant="solid">
                <AlertIcon />
                {error.message}
            </Alert>
        );
    }

    return data && data.length > 0 ? (
        <Accordion allowToggle reduceMotion defaultIndex={0}>
            {sortedConversations.map((conversation: ConversationResponse, i: number) => (
                <ConversationItem key={i} conversation={conversation} engagementId={engagementId} projectId={projectId} />
            ))}
        </Accordion>
    ) : (
        <Box>
            <Text>There are no conversations for the request yet. Start a new conversation by pressing the button at the bottom of the screen.</Text>
        </Box>
    );
};

export default CoversationList;
