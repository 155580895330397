export function getDisplayName(format: string, projectCode: string, name: string) {
    const displayFormats: string[] = ["Code - Name", "Name - Code", "Name (Code)", "Name"];

    switch (format) {
        case displayFormats[0]:
            return `${projectCode} - ${name}`;
        case displayFormats[1]:
            return `${name} - ${projectCode}`;
        case displayFormats[2]:
            return `${name} (${projectCode})`;
        case displayFormats[3]:
            return `${name}`;
        default:
            return `${projectCode} - ${name}`;
    }
}
