import { FunctionComponent } from "react";

import { Box } from "@chakra-ui/react";
import { IconHeadset, IconUsersGroup } from "@tabler/icons-react";
import { NavItem } from "am-tax-fe-core";
import { useTranslation } from "react-i18next";

interface Props {
    show: boolean;
    expanded: boolean;
}

const NavFooter: FunctionComponent<Props> = ({ show, expanded }: Props) => {
    const { t } = useTranslation("LeftNav");
    return (
        <Box>
            <NavItem
                show={true}
                to={"/feedback"}
                leftIcon={<IconHeadset />}
                text="Feedback"
                itemKey={"feedback"}
                isExpanded={expanded}
                activeColor={"aqua.500"}
            />
            <NavItem
                show={show}
                to={"/admin"}
                leftIcon={<IconUsersGroup />}
                text={expanded ? t("adminDashboard") : ""}
                itemKey={"admin"}
                isExpanded={expanded}
                activeColor={"aqua.500"}
            />
        </Box>
    );
};

export default NavFooter;
