import { useMemo } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

import {
    invalidateDocumentCacheForEngagementAndFolder,
    invalidateEngagementRequestQueries,
    Request,
    useDeleteEngagementRequest,
    useEngagement,
    useEngagementRequest,
    useEngagementUsers,
    useFolderStructure,
    useSaveEngagementRequest,
    useUploadDocumentToEngagementRequest,
} from "../../api";
import { RequestArea } from "../../auth";
import { ProjectEngagement } from "../../components/RequestForm/RequestForm";
import { useEngagementAreaPermissions } from "../../hooks";
import { Mutation } from "../../utils/query-utils.ts";

export function useRequestFormForEngagement(engagementId: string, requestId: string) {
    const navigate = useNavigate();
    const location = useLocation();
    const areaPermissions = useEngagementAreaPermissions(RequestArea, engagementId);
    const projectEngagementQuery = useEngagement(engagementId, undefined);
    const deleteRequestQuery = useDeleteEngagementRequest();
    const requestQuery = useEngagementRequest(engagementId, requestId, !deleteRequestQuery.isPending && !deleteRequestQuery.isSuccess);
    const participantsQuery = useEngagementUsers(engagementId, undefined);
    const saveRequestQuery = useSaveEngagementRequest(true);
    const uploadFileToRequestQuery = useUploadDocumentToEngagementRequest(true);
    const folderStructureQuery = useFolderStructure(engagementId);

    const saveRequestMutation = useMemo(() => new Mutation(saveRequestQuery), [saveRequestQuery]);
    const deleteRequestMutation = useMemo(() => new Mutation(deleteRequestQuery), [deleteRequestQuery]);
    const uploadFileToRequestMutation = useMemo(() => new Mutation(uploadFileToRequestQuery), [uploadFileToRequestQuery]);

    const queryClient = useQueryClient();
    const onSaveComplete = (projectOrEngagement: ProjectEngagement, request: Request, filesUploaded?: boolean) => {
        const currFolderId = request.folderId || projectOrEngagement.clientDocumentsRootFolderId;
        invalidateEngagementRequestQueries(queryClient, request.engagementId, request.requestId);
        if (filesUploaded) {
            invalidateDocumentCacheForEngagementAndFolder(queryClient, request.engagementId, currFolderId);
        }
    };

    const canGoBack = location.key !== "default";
    const navigateBackTitle = canGoBack ? "Go Back" : "Back to Requests";

    const navigateBack = () => {
        if (canGoBack) {
            navigate(-1);
        } else {
            navigate(`/engagement/${engagementId}/requests/`, { relative: "path" });
        }
    };

    return {
        areaPermissions,
        projectEngagementQuery,
        requestQuery,
        participantsQuery,
        folderStructureQuery,
        saveRequestMutation,
        deleteRequestMutation,
        uploadFileToRequestMutation,
        onSaveComplete,
        navigateBack,
        navigateBackTitle,
    };
}
