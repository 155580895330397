import { FunctionComponent } from "react";

import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { EventForm } from "./EventForm.tsx";
import { useEventFormForEngagement } from "./useEventFormForEngagement.ts";

export const EngagementEventModal: FunctionComponent = () => {
    const { engagementId, eventId } = useParams();
    const eventFormProps = useEventFormForEngagement(engagementId ?? "", eventId ?? "");

    return (
        <Modal
            isOpen={true}
            onClose={() => {
                window.history.back();
            }}
            size={"3xl"}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{`${eventFormProps.eventQuery.data?.eventId ? "Edit" : "Add"} Event`}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <EventForm {...eventFormProps} />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
